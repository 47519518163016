/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailingListPermissionDto
 */
export interface MailingListPermissionDto {
    /**
     * 
     * @type {string}
     * @memberof MailingListPermissionDto
     */
    name: MailingListPermissionDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof MailingListPermissionDto
     */
    action: MailingListPermissionDtoActionEnum;
}


/**
 * @export
 */
export const MailingListPermissionDtoNameEnum = {
    MAILING_LIST: 'MAILING_LIST'
} as const;
export type MailingListPermissionDtoNameEnum = typeof MailingListPermissionDtoNameEnum[keyof typeof MailingListPermissionDtoNameEnum];

/**
 * @export
 */
export const MailingListPermissionDtoActionEnum = {
    SEE_LIST_VIEW: 'CAN_SEE_LIST_VIEW',
    SEE_DETAILS_OWN: 'CAN_SEE_DETAILS_OWN',
    SEE_DETAILS: 'CAN_SEE_DETAILS',
    EDIT_OWN: 'CAN_EDIT_OWN',
    EDIT: 'CAN_EDIT',
    CREATE: 'CAN_CREATE',
    DELETE_OWN: 'CAN_DELETE_OWN',
    DELETE: 'CAN_DELETE'
} as const;
export type MailingListPermissionDtoActionEnum = typeof MailingListPermissionDtoActionEnum[keyof typeof MailingListPermissionDtoActionEnum];


/**
 * Check if a given object implements the MailingListPermissionDto interface.
 */
export function instanceOfMailingListPermissionDto(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('action' in value)) return false;
    return true;
}

export function MailingListPermissionDtoFromJSON(json: any): MailingListPermissionDto {
    return MailingListPermissionDtoFromJSONTyped(json, false);
}

export function MailingListPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingListPermissionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'action': json['action'],
    };
}

export function MailingListPermissionDtoToJSON(value?: MailingListPermissionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'action': value['action'],
    };
}

