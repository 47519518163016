import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { DescriptionItem } from '../../../ui/description';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchOffer } from '../offer-queries';
import OfferReference from '../offer-reference/offer-reference';

interface OfferMailingInfoAddonProps {
  offerId: string;
}

export function OfferMailingInfoAddon({ offerId }: OfferMailingInfoAddonProps) {
  const offer = useFetcher(fetchOffer, { id: offerId });
  const { t } = useTranslation(['offer']);
  invariant(offer.id != null && offer.offerNumber != null);

  return (
    <DescriptionItem label={t('offer:offer')} span={2}>
      <OfferReference offer={{ id: offer.id, offerNumber: offer.offerNumber }} />
    </DescriptionItem>
  );
}
