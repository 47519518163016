import { breadcrumbAnatomy } from '@chakra-ui/anatomy';
import { mode, MultiStyleConfig } from '@chakra-ui/theme-tools';

const Breadcrumb: MultiStyleConfig<typeof breadcrumbAnatomy> = {
  parts: breadcrumbAnatomy.keys,
  baseStyle: (props) => ({
    item: {
      color: 'text.muted',
    },
    link: {
      maxWidth: 200,
      textDecoration: 'underline',
      textDecorationColor: mode('blackAlpha.300', 'whiteAlpha.400')(props),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
};

export default Breadcrumb;
