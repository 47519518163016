import React from 'react';
import invariant from 'tiny-invariant';
import { offerIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { MAILING_MODULE_TYPE_EXTENSION } from '../mailing/mailing-module-type-extension';
import { MAILING_TYPE_EXTENSION } from '../mailing/mailing-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { createOfferMailingExtension } from './mailing/offer-mailing-extension';
import OfferMailingModuleControl from './mailing/offer-mailing-module-control';
import { useOfferPermissionClassGroupExtension } from './offer-permissions';
import OfferSearchResult from './offer-search-result';

export default function OfferPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'OFFER',
    Component: OfferSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'OFFER',
    icon: offerIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.OFFER" />,
    necessaryPermission: 'OFFER:CAN_SEE_DETAILS',
  });
  registry.register(MAILING_TYPE_EXTENSION, createOfferMailingExtension());
  registry.register(MAILING_MODULE_TYPE_EXTENSION, {
    type: 'OFFER',
    moduleControl({ module, connectionId }) {
      invariant(module.moduleType === 'OFFER', 'Type must be offer');
      invariant(connectionId != null, 'Offer must not be null');

      return <OfferMailingModuleControl offerId={connectionId} />;
    },
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useOfferPermissionClassGroupExtension);
}
