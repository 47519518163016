/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A Mailing list.
 * @export
 * @interface MailingListDto
 */
export interface MailingListDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingListDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListDto
     */
    title: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailingListDto
     */
    emailAddresses?: Array<string>;
    /**
     * 
     * @type {Array<OwnerDto>}
     * @memberof MailingListDto
     */
    owners: Array<OwnerDto>;
    /**
     * 
     * @type {VersionDto}
     * @memberof MailingListDto
     */
    version?: VersionDto;
}

/**
 * Check if a given object implements the MailingListDto interface.
 */
export function instanceOfMailingListDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('owners' in value)) return false;
    return true;
}

export function MailingListDtoFromJSON(json: any): MailingListDto {
    return MailingListDtoFromJSONTyped(json, false);
}

export function MailingListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingListDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'emailAddresses': json['emailAddresses'] == null ? undefined : json['emailAddresses'],
        'owners': ((json['owners'] as Array<any>).map(OwnerDtoFromJSON)),
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
    };
}

export function MailingListDtoToJSON(value?: MailingListDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'emailAddresses': value['emailAddresses'],
        'owners': ((value['owners'] as Array<any>).map(OwnerDtoToJSON)),
        'version': VersionDtoToJSON(value['version']),
    };
}

