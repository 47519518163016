import Translate from '../../util/translate/translate';
import SubMenuItem from '../app/app-navigation/sub-menu-item';

const offerMenuItems: SubMenuItem[] = [
  {
    name: <Translate ns="app" i18nKey="main_navigation.offer" />,
    path: '/offers',
    neccessaryPermission: 'OFFER:CAN_SEE_LIST_VIEW',
  },
];

export default offerMenuItems;
