/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MailingListDto } from './MailingListDto';
import {
    MailingListDtoFromJSON,
    MailingListDtoFromJSONTyped,
    MailingListDtoToJSON,
} from './MailingListDto';

/**
 * 
 * @export
 * @interface MailingListSearchResultDto
 */
export interface MailingListSearchResultDto {
    /**
     * 
     * @type {string}
     * @memberof MailingListSearchResultDto
     */
    resultType: MailingListSearchResultDtoResultTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof MailingListSearchResultDto
     */
    score: number;
    /**
     * 
     * @type {MailingListDto}
     * @memberof MailingListSearchResultDto
     */
    result: MailingListDto;
}


/**
 * @export
 */
export const MailingListSearchResultDtoResultTypeEnum = {
    MAILING_LIST: 'MAILING_LIST'
} as const;
export type MailingListSearchResultDtoResultTypeEnum = typeof MailingListSearchResultDtoResultTypeEnum[keyof typeof MailingListSearchResultDtoResultTypeEnum];


/**
 * Check if a given object implements the MailingListSearchResultDto interface.
 */
export function instanceOfMailingListSearchResultDto(value: object): boolean {
    if (!('resultType' in value)) return false;
    if (!('score' in value)) return false;
    if (!('result' in value)) return false;
    return true;
}

export function MailingListSearchResultDtoFromJSON(json: any): MailingListSearchResultDto {
    return MailingListSearchResultDtoFromJSONTyped(json, false);
}

export function MailingListSearchResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingListSearchResultDto {
    if (json == null) {
        return json;
    }
    return {
        
        'resultType': json['resultType'],
        'score': json['score'],
        'result': MailingListDtoFromJSON(json['result']),
    };
}

export function MailingListSearchResultDtoToJSON(value?: MailingListSearchResultDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resultType': value['resultType'],
        'score': value['score'],
        'result': MailingListDtoToJSON(value['result']),
    };
}

