/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FailureDto,
  HistoryPageDto,
  KeyPlayerDto,
  KeyPlayerPageDto,
  KeyPlayerStatusCheckDto,
  KeyPlayersListSettingsDto,
  PageableDto,
  StringPageDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    KeyPlayerDtoFromJSON,
    KeyPlayerDtoToJSON,
    KeyPlayerPageDtoFromJSON,
    KeyPlayerPageDtoToJSON,
    KeyPlayerStatusCheckDtoFromJSON,
    KeyPlayerStatusCheckDtoToJSON,
    KeyPlayersListSettingsDtoFromJSON,
    KeyPlayersListSettingsDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    StringPageDtoFromJSON,
    StringPageDtoToJSON,
} from '../models/index';

export interface CheckKeyPlayerIsDeletableRequest {
    eventId: string;
    personId: string;
}

export interface CreateKeyPlayerRequest {
    keyPlayerDto: KeyPlayerDto;
}

export interface DeleteKeyPlayerRequest {
    id: string;
    eventId: string;
}

export interface FetchAllKeyPlayersForPersonRequest {
    eventId: string;
    personId: string;
}

export interface FetchKeyPlayerRequest {
    id: string;
    eventId: string;
}

export interface FetchKeyPlayerHistoryRequest {
    eventId: string;
    pageable?: PageableDto;
}

export interface FetchKeyPlayerListSettingsHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchKeyPlayersListRequest {
    eventIds?: Array<string>;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface FetchKeyPlayersListSettingsRequest {
    eventId: string;
}

export interface GetNotCopyableKeyPlayersRequest {
    eventId: string;
}

export interface HasKeyPlayerOnListRequest {
    eventId: string;
    personId: string;
}

export interface HasKeyPlayersRequest {
    eventId: string;
}

export interface SearchSectionsWithinListRequest {
    eventId: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateKeyPlayerRequest {
    id: string;
    keyPlayerDto: KeyPlayerDto;
}

export interface UpdateKeyPlayersListSettingsRequest {
    eventId: string;
    keyPlayersListSettingsDto: KeyPlayersListSettingsDto;
}

/**
 * 
 */
export class KeyPlayersListApi extends runtime.BaseAPI {

    /**
     * Get whether the specified key player can be deleted.
     */
    async checkKeyPlayerIsDeletableRaw(requestParameters: CheckKeyPlayerIsDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyPlayerStatusCheckDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling checkKeyPlayerIsDeletable().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling checkKeyPlayerIsDeletable().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/check-key-player-is-deletable`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyPlayerStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified key player can be deleted.
     */
    async checkKeyPlayerIsDeletable(requestParameters: CheckKeyPlayerIsDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyPlayerStatusCheckDto> {
        const response = await this.checkKeyPlayerIsDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create KeyPlayer.
     */
    async createKeyPlayerRaw(requestParameters: CreateKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['keyPlayerDto'] == null) {
            throw new runtime.RequiredError(
                'keyPlayerDto',
                'Required parameter "keyPlayerDto" was null or undefined when calling createKeyPlayer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-player`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: KeyPlayerDtoToJSON(requestParameters['keyPlayerDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create KeyPlayer.
     */
    async createKeyPlayer(requestParameters: CreateKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createKeyPlayerRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the KeyPlayer.
     */
    async deleteKeyPlayerRaw(requestParameters: DeleteKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteKeyPlayer().'
            );
        }

        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling deleteKeyPlayer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventId'] != null) {
            queryParameters['eventId'] = requestParameters['eventId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the KeyPlayer.
     */
    async deleteKeyPlayer(requestParameters: DeleteKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteKeyPlayerRaw(requestParameters, initOverrides);
    }

    /**
     * Get all key players for given person and event
     */
    async fetchAllKeyPlayersForPersonRaw(requestParameters: FetchAllKeyPlayersForPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KeyPlayerDto>>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchAllKeyPlayersForPerson().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling fetchAllKeyPlayersForPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/key-players-for-person`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KeyPlayerDtoFromJSON));
    }

    /**
     * Get all key players for given person and event
     */
    async fetchAllKeyPlayersForPerson(requestParameters: FetchAllKeyPlayersForPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KeyPlayerDto>> {
        const response = await this.fetchAllKeyPlayersForPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get key player object.
     */
    async fetchKeyPlayerRaw(requestParameters: FetchKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyPlayerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchKeyPlayer().'
            );
        }

        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchKeyPlayer().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['eventId'] != null) {
            queryParameters['eventId'] = requestParameters['eventId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyPlayerDtoFromJSON(jsonValue));
    }

    /**
     * Get key player object.
     */
    async fetchKeyPlayer(requestParameters: FetchKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyPlayerDto> {
        const response = await this.fetchKeyPlayerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history of the key players associated with the referenced event.
     */
    async fetchKeyPlayerHistoryRaw(requestParameters: FetchKeyPlayerHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchKeyPlayerHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/key-player-versions`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get history of the key players associated with the referenced event.
     */
    async fetchKeyPlayerHistory(requestParameters: FetchKeyPlayerHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchKeyPlayerHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get history of the key player list settings.
     */
    async fetchKeyPlayerListSettingsHistoryRaw(requestParameters: FetchKeyPlayerListSettingsHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchKeyPlayerListSettingsHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{id}/key-player-list-setting-versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get history of the key player list settings.
     */
    async fetchKeyPlayerListSettingsHistory(requestParameters: FetchKeyPlayerListSettingsHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchKeyPlayerListSettingsHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the key players list for given event ID(s).
     */
    async fetchKeyPlayersListRaw(requestParameters: FetchKeyPlayersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyPlayerPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['eventIds'] != null) {
            queryParameters['eventIds'] = requestParameters['eventIds']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyPlayerPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the key players list for given event ID(s).
     */
    async fetchKeyPlayersList(requestParameters: FetchKeyPlayersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyPlayerPageDto> {
        const response = await this.fetchKeyPlayersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the key players list settings object for given event ID.
     */
    async fetchKeyPlayersListSettingsRaw(requestParameters: FetchKeyPlayersListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyPlayersListSettingsDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling fetchKeyPlayersListSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players-list-settings/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyPlayersListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Get the key players list settings object for given event ID.
     */
    async fetchKeyPlayersListSettings(requestParameters: FetchKeyPlayersListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyPlayersListSettingsDto> {
        const response = await this.fetchKeyPlayersListSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of key players that will not be copied with event
     */
    async getNotCopyableKeyPlayersRaw(requestParameters: GetNotCopyableKeyPlayersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<KeyPlayerDto>>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling getNotCopyableKeyPlayers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/not-copyable-key-players`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KeyPlayerDtoFromJSON));
    }

    /**
     * Get a list of key players that will not be copied with event
     */
    async getNotCopyableKeyPlayers(requestParameters: GetNotCopyableKeyPlayersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<KeyPlayerDto>> {
        const response = await this.getNotCopyableKeyPlayersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the specified person is on the key players list.
     */
    async hasKeyPlayerOnListRaw(requestParameters: HasKeyPlayerOnListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling hasKeyPlayerOnList().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling hasKeyPlayerOnList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/has-key-player-on-list`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the specified person is on the key players list.
     */
    async hasKeyPlayerOnList(requestParameters: HasKeyPlayerOnListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.hasKeyPlayerOnListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the key players list for given event has entries.
     */
    async hasKeyPlayersRaw(requestParameters: HasKeyPlayersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling hasKeyPlayers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/has-key-players`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     * Get whether the key players list for given event has entries.
     */
    async hasKeyPlayers(requestParameters: HasKeyPlayersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.hasKeyPlayersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for sections within key player list of given event.
     */
    async searchSectionsWithinListRaw(requestParameters: SearchSectionsWithinListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringPageDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling searchSectionsWithinList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{eventId}/sections`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for sections within key player list of given event.
     */
    async searchSectionsWithinList(requestParameters: SearchSectionsWithinListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringPageDto> {
        const response = await this.searchSectionsWithinListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing key player.
     */
    async updateKeyPlayerRaw(requestParameters: UpdateKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateKeyPlayer().'
            );
        }

        if (requestParameters['keyPlayerDto'] == null) {
            throw new runtime.RequiredError(
                'keyPlayerDto',
                'Required parameter "keyPlayerDto" was null or undefined when calling updateKeyPlayer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KeyPlayerDtoToJSON(requestParameters['keyPlayerDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing key player.
     */
    async updateKeyPlayer(requestParameters: UpdateKeyPlayerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateKeyPlayerRaw(requestParameters, initOverrides);
    }

    /**
     * Update the key players list for given event.
     */
    async updateKeyPlayersListSettingsRaw(requestParameters: UpdateKeyPlayersListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KeyPlayersListSettingsDto>> {
        if (requestParameters['eventId'] == null) {
            throw new runtime.RequiredError(
                'eventId',
                'Required parameter "eventId" was null or undefined when calling updateKeyPlayersListSettings().'
            );
        }

        if (requestParameters['keyPlayersListSettingsDto'] == null) {
            throw new runtime.RequiredError(
                'keyPlayersListSettingsDto',
                'Required parameter "keyPlayersListSettingsDto" was null or undefined when calling updateKeyPlayersListSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/key-players-list-settings/{eventId}`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters['eventId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: KeyPlayersListSettingsDtoToJSON(requestParameters['keyPlayersListSettingsDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyPlayersListSettingsDtoFromJSON(jsonValue));
    }

    /**
     * Update the key players list for given event.
     */
    async updateKeyPlayersListSettings(requestParameters: UpdateKeyPlayersListSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KeyPlayersListSettingsDto> {
        const response = await this.updateKeyPlayersListSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
