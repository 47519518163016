import invariant from 'tiny-invariant';
import { mailingListIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { MAILING_RECIPIENT_EXTENSION } from '../mailing/mailing-recipient-extension';
import { MAILING_TYPE_EXTENSION } from '../mailing/mailing-type-extension';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useMailingListPermissionClassGroupExtension } from './mailing-list-permissions';
import { isMailingListRecipientLink } from './mailing-list-recipient/mailing-list-recipient-link';
import MailingListReference from './mailing-list-reference/mailing-list-reference';
import MailingListSearchResult from './mailing-list-search-result';
import { createNewsletterMailing } from './newsletter-mailing/newsletter-mailing-extension';

export default function MailingListPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'MAILING_LIST',
    Component: MailingListSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'MAILING_LIST',
    icon: mailingListIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.MAILING_LIST" />,
    necessaryPermission: 'MAILING_LIST:CAN_SEE_DETAILS_OWN',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useMailingListPermissionClassGroupExtension);
  registry.register(MAILING_TYPE_EXTENSION, createNewsletterMailing());
  registry.register(MAILING_RECIPIENT_EXTENSION, {
    type: 'MAILING_LIST',
    viewer(link) {
      invariant(isMailingListRecipientLink(link), `Unexpected link type ${link.type}`);
      return <MailingListReference mailingList={link.linkedRecipient} />;
    },
    asString(link) {
      invariant(isMailingListRecipientLink(link), `Unexpected link type ${link.type}`);
      return link.linkedRecipient.title;
    },
  });
}
