import React from 'react';
import { GroupReferenceDto } from '../../../api';
import { groupIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

export interface GroupReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  groupReference: GroupReferenceDto;
}

export default function GroupReference({ groupReference, ...props }: GroupReferenceProps) {
  return (
    <Reference
      icon={groupIcon}
      to={`/groups/${groupReference.id}/personRelations`}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('GROUP:CAN_SEE_DETAILS') ||
          [...groupReference.ownerSectionIds, ...groupReference.coOwnerSectionIds].some((sectionId) =>
            hasPermissionFromSection(sectionId, 'GROUP:CAN_SEE_DETAILS_OWN'),
          )
        );
      }}
      {...props}
    >
      {groupReference.name}
    </Reference>
  );
}
