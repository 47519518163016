import { Flex, Grid, GridItem, IconButton, Tooltip } from '@chakra-ui/react';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { CompanyDto, FormatCompanyListItemDto } from '../../../../api';
import DataTable, { DataTableColumn, DataTableState, useDataTableState } from '../../../../ui/data-table';
import DateFilter from '../../../../ui/data-table/filter/date-filter';
import DateRangeFilter from '../../../../ui/data-table/filter/date-range-filter';
import EnumFilter from '../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../ui/data-table/use-request-params';
import LastModifiedLabel from '../../../../ui/version/last-modified-label';
import fallbackMiddleware from '../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import FormatReference from '../../../format/format-reference/format-reference';
import usePermission from '../../../permission/use-permission';
import { CONNECTION_TYPE_OPTIONS, STATUS_OPTIONS } from '../format-company-enum-constants';
import { fetchFormatsByCompany, formatCompanyFetcher } from '../format-company-queries';
import FormatCompanyTerminateButton from '../format-to-company-viewer/format-company-terminate-button';

const companyFormatTableColumns: DataTableColumn<FormatCompanyListItemDto>[] = [
  {
    key: 'format',
    name: <Translate ns="format" i18nKey="companyConnections.formats" />,
    cellProps: {
      whiteSpace: 'nowrap',
      textAlign: 'left',
    },
    renderCell: (data: FormatCompanyListItemDto) => <FormatReference format={data.format!} />,
    filter: (
      <StringFilter
        label={<Translate ns="format" i18nKey="companyConnections.formats" />}
        availableOperators={['CONTAIN', 'NOT_CONTAIN', 'START_WITH', 'NOT_START_WITH', 'END_WITH', 'NOT_END_WITH']}
      />
    ),
    isSortable: true,
    sortProperty: 'format',
  },
  {
    key: 'connectionType',
    name: <Translate ns="format" i18nKey="formatCompany.connectionType" />,
    renderCell: (data) => (
      <Translate ns="format">{(t) => t(`formatCompany.connectionTypeOptions.${data.connectionType}`)}</Translate>
    ),
    isSortable: true,
    filter: (
      <Translate ns="format">
        {(t) => (
          <EnumFilter
            label={<Translate ns="format" i18nKey="formatCompany.connectionType" />}
            options={CONNECTION_TYPE_OPTIONS}
            renderOptionLabel={(key) => t(`formatCompany.connectionTypeOptions.${key}`)}
          />
        )}
      </Translate>
    ),
  },
  {
    key: 'validityPeriod',
    name: <Translate ns="format" i18nKey="formatCompany.validityPeriod" />,
    cellProps: {
      whiteSpace: 'nowrap',
      width: '20%',
    },
    renderCell: (format) => (
      <Translate ns="common">{(t) => t('format.date_range', { dateRange: format.validityPeriod })}</Translate>
    ),
    isSortable: true,
    sortProperty: 'validityPeriod.start',
    filter: <DateRangeFilter />,
  },
  {
    key: 'status',
    name: <Translate ns="format" i18nKey="formatCompany.status" />,
    renderCell: (data) => <Translate ns="format">{(t) => t(`formatCompany.statusOptions.${data.status}`)}</Translate>,
    isSortable: true,
    filter: (
      <Translate ns="format">
        {(t) => (
          <EnumFilter
            label={<Translate ns="format" i18nKey="formatCompany.status" />}
            options={STATUS_OPTIONS}
            renderOptionLabel={(key) => t(`formatCompany.statusOptions.${key}`)}
          />
        )}
      </Translate>
    ),
  },
  {
    key: 'modifiedAt',
    name: <Translate ns="common" i18nKey="viewer.last_modification" />,
    isSortable: true,
    renderCell: (data) => <LastModifiedLabel version={data.version} />,
    filter: <DateFilter showTimeSelect />,
    filterWidth: 'md',
  },
];

function useCompanyFormatListItems(state: DataTableState, companyId: string) {
  const requestParams = useRequestParams(state, [
    {
      property: 'format.name',
      direction: 'ASC',
    },
  ]);
  return useFetcher(
    fetchFormatsByCompany,
    {
      ...requestParams,
      id: companyId,
      filter: [...requestParams.filter],
    },
    { use: [fallbackMiddleware] },
  );
}

export default function CompanyFormatViewer({ company }: { company: CompanyDto }) {
  const { t } = useTranslation(['common', 'company', 'format']);
  const [state, setState] = useDataTableState();
  const page = useCompanyFormatListItems(state, company.id!);
  const { hasPermission } = usePermission();

  const actions = useCallback(
    (relation: FormatCompanyListItemDto) =>
      hasPermission('COMPANY-FORMAT:CAN_LINK') && (
        <Flex>
          <Tooltip label={t('format:connections.terminate')} placement="right-end">
            <IconButton
              as={FormatCompanyTerminateButton}
              connectedEntity={{ type: 'COMPANY', company: { id: company.id!, name: company.name } }}
              relation={relation}
              onSuccess={() => formatCompanyFetcher.mutate()}
              size="sm"
              variant="ghost"
              icon={<FontAwesomeIcon icon={faUnlink} />}
              aria-label={t('format:connections.terminate')}
            />
          </Tooltip>
        </Flex>
      ),
    [hasPermission, t, company.id, company.name],
  );

  const rowKey = React.useCallback((relation: FormatCompanyListItemDto) => {
    invariant(relation.id != null, 'Missing relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={companyFormatTableColumns}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
          actions={actions}
        />
      </GridItem>
    </Grid>
  );
}
