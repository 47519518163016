/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface MailingListListItemDto
 */
export interface MailingListListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof MailingListListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MailingListListItemDto
     */
    title: string;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof MailingListListItemDto
     */
    owners: Array<SectionReferenceDto>;
    /**
     * 
     * @type {VersionDto}
     * @memberof MailingListListItemDto
     */
    version?: VersionDto;
}

/**
 * Check if a given object implements the MailingListListItemDto interface.
 */
export function instanceOfMailingListListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('owners' in value)) return false;
    return true;
}

export function MailingListListItemDtoFromJSON(json: any): MailingListListItemDto {
    return MailingListListItemDtoFromJSONTyped(json, false);
}

export function MailingListListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingListListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'owners': ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
    };
}

export function MailingListListItemDtoToJSON(value?: MailingListListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'owners': ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'version': VersionDtoToJSON(value['version']),
    };
}

