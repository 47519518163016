/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ModuleTypeDto = {
    TEXT: 'TEXT',
    RSVP: 'RSVP',
    TICKET: 'TICKET',
    SALUTATION: 'SALUTATION',
    PICTURE: 'PICTURE',
    PORTRAIT: 'PORTRAIT',
    ANCHOR: 'ANCHOR',
    FLEX: 'FLEX',
    TICKET_WITHOUT_PLACEMENT: 'TICKET_WITHOUT_PLACEMENT',
    CODE: 'CODE',
    DYNAMIC_TEXT: 'DYNAMIC_TEXT',
    CODE_CONFIG: 'CODE_CONFIG',
    OFFER: 'OFFER'
} as const;
export type ModuleTypeDto = typeof ModuleTypeDto[keyof typeof ModuleTypeDto];


export function ModuleTypeDtoFromJSON(json: any): ModuleTypeDto {
    return ModuleTypeDtoFromJSONTyped(json, false);
}

export function ModuleTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleTypeDto {
    return json as ModuleTypeDto;
}

export function ModuleTypeDtoToJSON(value?: ModuleTypeDto | null): any {
    return value as any;
}

