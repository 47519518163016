import React from 'react';
import MailingTypeExtension from '../mailing-type-extension';
import StandardInfoMailingRecipientEditor from './standard-info-mailing-recipient-editor';
import StandardInfoMailingRecipientLister from './standard-info-mailing-recipient-lister';

export function createStandardInfoMailing(): MailingTypeExtension {
  return {
    type: 'STANDARD_INFO',
    recipientEditorAddon({ mailingId }) {
      return <StandardInfoMailingRecipientEditor mailingId={mailingId} />;
    },
    recipientListerAddon({ mailingId }) {
      return <StandardInfoMailingRecipientLister mailingId={mailingId} />;
    },
  };
}
