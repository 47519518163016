import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import useFetcher from '../../util/swr/use-fetcher';
import Translate from '../../util/translate/translate';
import useParam from '../../util/use-params/use-params';
import FILM_CHILD_ROUTES from './film-child-routes';
import { fetchFilm } from './film-queries';
import { mostRelevantTitle } from './film-utils';

export const PARAM_FILM_ID = 'filmId';

const filmRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'films',
    handle: {
      breadcrumb: <Translate ns="film" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./film-lister/film-lister-route'),
      },
      {
        path: `:${PARAM_FILM_ID}`,
        handle: {
          breadcrumb: <FilmBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./film-page/film-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./film-viewer/film-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./film-history/film-history-route'),
              },
              {
                path: 'persons',
                lazy: () => import('./film-person/film-to-person-viewer/film-persons-route'),
              },
            ],
          },

          ...registry.getAll(FILM_CHILD_ROUTES).flat(),
        ],
      },
    ],
  },
];

function FilmBreadcrumb() {
  const id = useParam('filmId');
  const film = useFetcher(fetchFilm, { id }, { suspense: true });

  return mostRelevantTitle(film);
}

export default filmRoutes;
