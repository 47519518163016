/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessGroupReferenceDto } from './AccessGroupReferenceDto';
import {
    AccessGroupReferenceDtoFromJSON,
    AccessGroupReferenceDtoFromJSONTyped,
    AccessGroupReferenceDtoToJSON,
} from './AccessGroupReferenceDto';
import type { AccountingItemsDto } from './AccountingItemsDto';
import {
    AccountingItemsDtoFromJSON,
    AccountingItemsDtoFromJSONTyped,
    AccountingItemsDtoToJSON,
} from './AccountingItemsDto';
import type { CompanyUpdateDataDto } from './CompanyUpdateDataDto';
import {
    CompanyUpdateDataDtoFromJSON,
    CompanyUpdateDataDtoFromJSONTyped,
    CompanyUpdateDataDtoToJSON,
} from './CompanyUpdateDataDto';
import type { FormatCompanyRelationDto } from './FormatCompanyRelationDto';
import {
    FormatCompanyRelationDtoFromJSON,
    FormatCompanyRelationDtoFromJSONTyped,
    FormatCompanyRelationDtoToJSON,
} from './FormatCompanyRelationDto';
import type { FormatUpdateDataDto } from './FormatUpdateDataDto';
import {
    FormatUpdateDataDtoFromJSON,
    FormatUpdateDataDtoFromJSONTyped,
    FormatUpdateDataDtoToJSON,
} from './FormatUpdateDataDto';
import type { OrderDto } from './OrderDto';
import {
    OrderDtoFromJSON,
    OrderDtoFromJSONTyped,
    OrderDtoToJSON,
} from './OrderDto';
import type { PersonUpdateDataDto } from './PersonUpdateDataDto';
import {
    PersonUpdateDataDtoFromJSON,
    PersonUpdateDataDtoFromJSONTyped,
    PersonUpdateDataDtoToJSON,
} from './PersonUpdateDataDto';

/**
 * Represents the verification of an order.
 * @export
 * @interface OrderVerificationDto
 */
export interface OrderVerificationDto {
    /**
     * 
     * @type {OrderDto}
     * @memberof OrderVerificationDto
     */
    order: OrderDto;
    /**
     * 
     * @type {Array<AccessGroupReferenceDto>}
     * @memberof OrderVerificationDto
     */
    accessGroups: Array<AccessGroupReferenceDto>;
    /**
     * 
     * @type {PersonUpdateDataDto}
     * @memberof OrderVerificationDto
     */
    updatedPerson?: PersonUpdateDataDto;
    /**
     * 
     * @type {Array<CompanyUpdateDataDto>}
     * @memberof OrderVerificationDto
     */
    updatedCompanies: Array<CompanyUpdateDataDto>;
    /**
     * 
     * @type {Array<FormatUpdateDataDto>}
     * @memberof OrderVerificationDto
     */
    updatedFormats: Array<FormatUpdateDataDto>;
    /**
     * 
     * @type {Array<AccountingItemsDto>}
     * @memberof OrderVerificationDto
     */
    productDiscounts?: Array<AccountingItemsDto>;
    /**
     * 
     * @type {Array<FormatCompanyRelationDto>}
     * @memberof OrderVerificationDto
     */
    formatCompanyRelations: Array<FormatCompanyRelationDto>;
}

/**
 * Check if a given object implements the OrderVerificationDto interface.
 */
export function instanceOfOrderVerificationDto(value: object): boolean {
    if (!('order' in value)) return false;
    if (!('accessGroups' in value)) return false;
    if (!('updatedCompanies' in value)) return false;
    if (!('updatedFormats' in value)) return false;
    if (!('formatCompanyRelations' in value)) return false;
    return true;
}

export function OrderVerificationDtoFromJSON(json: any): OrderVerificationDto {
    return OrderVerificationDtoFromJSONTyped(json, false);
}

export function OrderVerificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderVerificationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'order': OrderDtoFromJSON(json['order']),
        'accessGroups': ((json['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoFromJSON)),
        'updatedPerson': json['updatedPerson'] == null ? undefined : PersonUpdateDataDtoFromJSON(json['updatedPerson']),
        'updatedCompanies': ((json['updatedCompanies'] as Array<any>).map(CompanyUpdateDataDtoFromJSON)),
        'updatedFormats': ((json['updatedFormats'] as Array<any>).map(FormatUpdateDataDtoFromJSON)),
        'productDiscounts': json['productDiscounts'] == null ? undefined : ((json['productDiscounts'] as Array<any>).map(AccountingItemsDtoFromJSON)),
        'formatCompanyRelations': ((json['formatCompanyRelations'] as Array<any>).map(FormatCompanyRelationDtoFromJSON)),
    };
}

export function OrderVerificationDtoToJSON(value?: OrderVerificationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'order': OrderDtoToJSON(value['order']),
        'accessGroups': ((value['accessGroups'] as Array<any>).map(AccessGroupReferenceDtoToJSON)),
        'updatedPerson': PersonUpdateDataDtoToJSON(value['updatedPerson']),
        'updatedCompanies': ((value['updatedCompanies'] as Array<any>).map(CompanyUpdateDataDtoToJSON)),
        'updatedFormats': ((value['updatedFormats'] as Array<any>).map(FormatUpdateDataDtoToJSON)),
        'productDiscounts': value['productDiscounts'] == null ? undefined : ((value['productDiscounts'] as Array<any>).map(AccountingItemsDtoToJSON)),
        'formatCompanyRelations': ((value['formatCompanyRelations'] as Array<any>).map(FormatCompanyRelationDtoToJSON)),
    };
}

