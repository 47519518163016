import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import React from 'react';
import { Link, RouteObject, useLocation, useMatches } from 'react-router-dom';

export type BreadcrumbHandle = {
  breadcrumb?: React.ReactNode;
};

export type BreadcrumbRouteObject = RouteObject & {
  handle?: BreadcrumbHandle;
  children?: BreadcrumbRouteObject[];
};

function isBreadcrumbHandle(handle: unknown): handle is BreadcrumbHandle {
  return typeof handle === 'object' && handle != null && 'breadcrumb' in handle;
}

export default function Breadcrumbs() {
  const location = useLocation();
  const matches = useMatches();
  const breadcrumbMatches = matches?.filter(
    (match): match is typeof match & { handle: BreadcrumbHandle } =>
      isBreadcrumbHandle(match.handle) && match.handle.breadcrumb != null,
  );

  if (breadcrumbMatches == null) {
    return null;
  }

  if (breadcrumbMatches.length === 0) {
    return null;
  }

  return (
    <Breadcrumb fontSize="sm">
      {breadcrumbMatches.map((match) => {
        const isCurrentPage = match.pathname === location.pathname;

        return (
          <BreadcrumbItem key={match.pathname} isCurrentPage={isCurrentPage}>
            <BreadcrumbLink as={Link} to={match.pathname}>
              {match.handle.breadcrumb}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}
