import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { mailingListIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function MailingListSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'MAILING_LIST', `${result.resultType} is no instance of MailingListSearchResult`);

  const mailingList = result.result;
  const { t } = useTranslation(['mailing_list']);

  return (
    <SearchResult
      icon={mailingListIcon}
      title={mailingList.title}
      link={`/mailing-lists/${mailingList.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission={({ hasPermission, hasPermissionFromSection }) => {
        return (
          hasPermission('MAILING_LIST:CAN_SEE_DETAILS') ||
          mailingList.owners.some((owner) =>
            hasPermissionFromSection(owner.section.id, 'MAILING_LIST:CAN_SEE_DETAILS_OWN'),
          )
        );
      }}
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('mailing_list:title'),
            element: mailingList.title,
          },
          {
            label: t('mailing_list:owners.label'),
            element: mailingList.owners.map((owner) => owner.section.name).join(', '),
          },
        ]}
      />
    </SearchResult>
  );
}
