import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import OfferBreadcrumb from './common/offer-breadcrumb';

const offerRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'offers',
    handle: {
      breadcrumb: <Translate ns="offer" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./offer-lister/offer-lister-route'),
      },
      {
        path: 'new',
        lazy: () => import('./offer-editor/new-offer-route'),
      },
      {
        path: ':offerId',
        handle: {
          breadcrumb: <OfferBreadcrumb />,
        },
        children: [
          {
            path: '',
            lazy: () => import('./offer-page/offer-page-route'),
            children: [
              {
                path: '',
                lazy: () => import('./offer-viewer/offer-viewer-route'),
              },
              {
                path: 'history',
                lazy: () => import('./offer-history/offer-history-route'),
              },
            ],
          },
          {
            path: 'edit',
            lazy: () => import('./offer-editor/edit-offer-route'),
          },
          {
            path: 'replace',
            lazy: () => import('./offer-editor/replace-offer-route'),
          },
        ],
      },
    ],
  },
];
export default offerRoutes;
