import React from 'react';
import { MailingListReferenceDto } from '../../../api';
import { mailingListIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface ListReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  mailingList: MailingListReferenceDto;
}

export default function MailingListReference({ mailingList, ...props }: ListReferenceProps) {
  return (
    <Reference icon={mailingListIcon} to={'/mailing-lists/' + mailingList.id} {...props}>
      {mailingList.title}
    </Reference>
  );
}
