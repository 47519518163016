import { useTranslation } from 'react-i18next';
import { PermissionClassGroupExtension } from '../permission-class-group/permission-class-group-extension';

export function useMailingListPermissionClassGroupExtension(): PermissionClassGroupExtension<'MAILING_LIST'> {
  const { t } = useTranslation(['mailing_list']);

  return {
    name: 'MAILING_LIST',
    label: t('mailing_list:mailingList'),
    getActionLabel: (action) => t(`mailing_list:permission.${action}`),
    getAdditionalRequirement: (action) =>
      t(`mailing_list:permissionAdditionalRequirements.${action}`, { defaultValue: '' }),
    getInfo: (action) => t(`mailing_list:permissionInfo.${action}`, { defaultValue: '' }),
  };
}
