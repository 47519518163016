import { offerIcon } from '../../../ui/icons/business-objects';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';

interface OfferReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  offer: { id: string; offerNumber: string };
}

export default function OfferReference({ offer, ...props }: OfferReferenceProps) {
  return (
    <Reference icon={offerIcon} to={'/offers/' + offer.id} necessaryPermission="OFFER:CAN_SEE_DETAILS" {...props}>
      {offer.offerNumber}
    </Reference>
  );
}
