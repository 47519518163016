import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RecipientLinkDto } from '../../../api';
import recipientApi from '../../../data-access/recipient-api';
import FormControl from '../../../ui/form/form-control';
import MultiValueAsyncSelect from '../../../ui/select/multi-value-async-select';
import { isMailingListRecipientLink } from './mailing-list-recipient-link';

export interface AddMailingListRecipients {
  mailingListRecipients: RecipientLinkDto[];
}

export default function MailingListMailingRecipientControl({ mailingId }: { mailingId: string }) {
  const { t } = useTranslation('mailing');
  const { field } = useController<AddMailingListRecipients, 'mailingListRecipients'>({ name: 'mailingListRecipients' });

  return (
    <FormControl<AddMailingListRecipients> label={t('recipients.mailingList')} name="mailingListRecipients">
      <MultiValueAsyncSelect<RecipientLinkDto>
        {...field}
        values={field.value}
        loadOptions={async (value: string, size: number) => {
          const { content } = await recipientApi.fetchPossibleRecipients({
            id: mailingId,
            pageable: { size },
            q: value,
            recipientType: 'MAILING_LIST',
          });

          return content;
        }}
        defaultOptions={false}
        renderLabel={(list) => isMailingListRecipientLink(list) && list.linkedRecipient.title}
        optionIdentifier={(recipient) => recipient.id}
      />
    </FormControl>
  );
}
