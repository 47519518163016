/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccountingIssuerDto } from './AccountingIssuerDto';
import {
    AccountingIssuerDtoFromJSON,
    AccountingIssuerDtoFromJSONTyped,
    AccountingIssuerDtoToJSON,
} from './AccountingIssuerDto';
import type { AccountingItemsDto } from './AccountingItemsDto';
import {
    AccountingItemsDtoFromJSON,
    AccountingItemsDtoFromJSONTyped,
    AccountingItemsDtoToJSON,
} from './AccountingItemsDto';
import type { AccountingRecipientDto } from './AccountingRecipientDto';
import {
    AccountingRecipientDtoFromJSON,
    AccountingRecipientDtoFromJSONTyped,
    AccountingRecipientDtoToJSON,
} from './AccountingRecipientDto';
import type { ClosedDateRangeDto } from './ClosedDateRangeDto';
import {
    ClosedDateRangeDtoFromJSON,
    ClosedDateRangeDtoFromJSONTyped,
    ClosedDateRangeDtoToJSON,
} from './ClosedDateRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof OfferDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof OfferDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof OfferDto
     */
    offerNumber?: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof OfferDto
     */
    edition?: EditionReferenceDto;
    /**
     * 
     * @type {AccountingRecipientDto}
     * @memberof OfferDto
     */
    recipient: AccountingRecipientDto;
    /**
     * 
     * @type {AccountingIssuerDto}
     * @memberof OfferDto
     */
    issuer: AccountingIssuerDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof OfferDto
     */
    document?: FileMetadataDto;
    /**
     * 
     * @type {Array<AccountingItemsDto>}
     * @memberof OfferDto
     */
    items: Array<AccountingItemsDto>;
    /**
     * 
     * @type {ClosedDateRangeDto}
     * @memberof OfferDto
     */
    validityPeriod: ClosedDateRangeDto;
}

/**
 * Check if a given object implements the OfferDto interface.
 */
export function instanceOfOfferDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('recipient' in value)) return false;
    if (!('issuer' in value)) return false;
    if (!('items' in value)) return false;
    if (!('validityPeriod' in value)) return false;
    return true;
}

export function OfferDtoFromJSON(json: any): OfferDto {
    return OfferDtoFromJSONTyped(json, false);
}

export function OfferDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'offerNumber': json['offerNumber'] == null ? undefined : json['offerNumber'],
        'edition': json['edition'] == null ? undefined : EditionReferenceDtoFromJSON(json['edition']),
        'recipient': AccountingRecipientDtoFromJSON(json['recipient']),
        'issuer': AccountingIssuerDtoFromJSON(json['issuer']),
        'document': json['document'] == null ? undefined : FileMetadataDtoFromJSON(json['document']),
        'items': ((json['items'] as Array<any>).map(AccountingItemsDtoFromJSON)),
        'validityPeriod': ClosedDateRangeDtoFromJSON(json['validityPeriod']),
    };
}

export function OfferDtoToJSON(value?: OfferDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'offerNumber': value['offerNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'recipient': AccountingRecipientDtoToJSON(value['recipient']),
        'issuer': AccountingIssuerDtoToJSON(value['issuer']),
        'document': FileMetadataDtoToJSON(value['document']),
        'items': ((value['items'] as Array<any>).map(AccountingItemsDtoToJSON)),
        'validityPeriod': ClosedDateRangeDtoToJSON(value['validityPeriod']),
    };
}

