/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RecipientTypeDto = {
    PERSON_ON_GUEST_LIST: 'PERSON_ON_GUEST_LIST',
    PERSON: 'PERSON',
    EMAIL: 'EMAIL',
    ACCOUNT: 'ACCOUNT',
    COMPANY: 'COMPANY',
    LIST: 'LIST',
    MAILING_LIST: 'MAILING_LIST'
} as const;
export type RecipientTypeDto = typeof RecipientTypeDto[keyof typeof RecipientTypeDto];


export function RecipientTypeDtoFromJSON(json: any): RecipientTypeDto {
    return RecipientTypeDtoFromJSONTyped(json, false);
}

export function RecipientTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipientTypeDto {
    return json as RecipientTypeDto;
}

export function RecipientTypeDtoToJSON(value?: RecipientTypeDto | null): any {
    return value as any;
}

