import DataTable from '../../../ui/data-table/data-table';
import useRecipientLister from '../../mailing/common/use-recipient-lister';
import useMailingListRecipientColumns from '../mailing-list-recipient/use-mailing-list-recipient-columns';

export default function NewsletterMailingRecipientLister({ mailingId }: { mailingId: string }) {
  const { state, setState, recipientPage } = useRecipientLister(mailingId);
  const columns = useMailingListRecipientColumns(mailingId);

  return (
    <DataTable
      rowKey={(recipient) => recipient.id!}
      page={recipientPage}
      columns={columns}
      state={state}
      onStateChange={setState}
      isPageable
    />
  );
}
