import { Flex, Grid, GridItem, Link, Menu, MenuItem, MenuList, Portal, Wrap, WrapItem } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupCompanyDtoStatusEnum, GroupCompanyRelationListItemDto, GroupDto, OwnerTypeDto } from '../../../../../api';
import { BurgerMenuButton } from '../../../../../ui/burger-menu-button/burger-menu-button';
import DataTable, {
  DataTableColumn,
  DataTableState,
  DataTableTruncatedText,
  useDataTableState,
} from '../../../../../ui/data-table';
import EnumFilter from '../../../../../ui/data-table/filter/enum-filter';
import StringFilter from '../../../../../ui/data-table/filter/string-filter';
import useRequestParams from '../../../../../ui/data-table/use-request-params';
import Optional from '../../../../../ui/optional/optional';
import { linkPhoneNumberString } from '../../../../../ui/phone-number/link-phone-number';
import fallbackMiddleware from '../../../../../util/swr/fallback-middleware';
import useFetcher from '../../../../../util/swr/use-fetcher';
import CompanyReference from '../../../../company/company-reference/company-reference';
import usePermission from '../../../../permission/use-permission';
import PersonReference from '../../../../person/person-reference/person-reference';
import GroupCompanyDeleteButton from '../group-company-delete-button/group-company-delete-button';
import { groupCompanyFetcher, searchGroupCompanyRelationListItems } from '../group-company-queries';

function useGroupCompanyRelations(state: DataTableState, groupId: string) {
  const requestParams = useRequestParams(state, [{ property: 'company.name', direction: 'ASC' }]);

  return useFetcher(
    searchGroupCompanyRelationListItems,
    {
      ...requestParams,
      id: groupId,
    },
    { use: [fallbackMiddleware] },
  );
}

export default function GroupCompanyRelationDataTable({ group }: { group: GroupDto }) {
  const [state, setState] = useDataTableState();
  const { t } = useTranslation(['common', 'company', 'relations', 'group']);
  const page = useGroupCompanyRelations(state, group.id!);
  const { hasPermission, hasPermissionFromSection } = usePermission();
  const canLink =
    hasPermission('GROUP:CAN_LINK_GROUP_WITH_COMPANY') ||
    group.owners
      .filter((owner) => owner.type == OwnerTypeDto.OWNER)
      .some((groupOwner) => hasPermissionFromSection(groupOwner.section.id, 'GROUP:CAN_LINK_GROUP_WITH_COMPANY_OWN'));

  const columns: DataTableColumn<GroupCompanyRelationListItemDto>[] = React.useMemo(() => {
    const columns: DataTableColumn<GroupCompanyRelationListItemDto>[] = [
      {
        key: 'company.name',
        name: t('company:name'),
        cellProps: {
          whiteSpace: 'nowrap',
        },
        isSortable: true,
        sticky: true,
        renderCell: (relation) => (
          <DataTableTruncatedText>
            <CompanyReference
              companyReference={{ id: relation.company.id!, name: relation.company.name }}
            ></CompanyReference>
          </DataTableTruncatedText>
        ),
        filter: <StringFilter label={t('company:name')} />,
      },
      {
        key: 'company.status',
        name: t('company:status'),
        isSortable: true,
        renderCell: (relation) => t(`company:statusLabel.${relation.company.status}`),
        filter: (
          <EnumFilter
            label={t('company:status')}
            options={[
              GroupCompanyDtoStatusEnum.ACTIVE,
              GroupCompanyDtoStatusEnum.INACTIVE,
              GroupCompanyDtoStatusEnum.HISTORIC,
              GroupCompanyDtoStatusEnum.UNCONFIRMED,
            ]}
            renderOptionLabel={(key) => t(`company:statusLabel.${key}`)}
          />
        ),
      },
      {
        key: 'company.companyAdmins',
        name: t('company:externalAdmin.lister'),
        renderCell: (relation) => (
          <Optional>
            {relation.company.companyAdmins != null && relation.company.companyAdmins?.length > 0 && (
              <Wrap>
                {relation.company.companyAdmins?.map((item, index) => (
                  <WrapItem key={index}>
                    <PersonReference
                      personReference={{
                        id: item.id,
                        firstName: item.firstName,
                        surname: item.surname,
                      }}
                      hidePersonKey
                    />
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </Optional>
        ),
        filter: (
          <StringFilter label={t('company:externalAdmin.lister')} availableOperators={['CONTAIN', 'NOT_CONTAIN']} />
        ),
      },
      {
        key: 'company.emailAddress',
        name: t('company:contacts.email.email_address'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        isSortable: true,
        renderCell: (relation) => <Optional>{relation.company.emailAddress}</Optional>,
        filter: <StringFilter label={t('company:contacts.email.email_address')} />,
      },
      {
        key: 'company.phoneNumber',
        name: t('company:contacts.phone_number.table.number'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        isSortable: true,
        renderCell: (relation) => (
          <Optional>
            {relation.company.phoneNumber != null && (
              <Link href={linkPhoneNumberString(relation.company.phoneNumber.raw)} isExternal>
                {relation.company.phoneNumber.pretty}
              </Link>
            )}
          </Optional>
        ),
        filter: <StringFilter label={t('company:contacts.email.email_address')} />,
      },
    ];

    return columns;
  }, [t]);

  const actions = useCallback(
    (relation: GroupCompanyRelationListItemDto) => {
      return (
        canLink && (
          <Flex>
            <Menu>
              <BurgerMenuButton size="sm" />
              <Portal>
                <MenuList>
                  <MenuItem
                    as={GroupCompanyDeleteButton}
                    relation={relation}
                    color="text.error"
                    onSuccess={() => groupCompanyFetcher.mutate()}
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  >
                    {t('group:connections.remove_company')}
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        )
      );
    },
    [canLink, t],
  );

  const rowKey = React.useCallback((relation: GroupCompanyRelationListItemDto) => {
    invariant(relation.id != null, 'Missing group company relation id');

    return relation.id;
  }, []);

  return (
    <Grid gridTemplateRows="1fr auto" height="full" gridRowGap={4}>
      <GridItem minHeight={0}>
        <DataTable
          page={page == null ? { content: [] } : page}
          state={state}
          columns={columns}
          actions={actions}
          rowKey={rowKey}
          onStateChange={setState}
          isPageable={true}
        />
      </GridItem>
    </Grid>
  );
}
