import React from 'react';
import useCallbackRef from '../../util/use-callback-ref/use-callback-ref';
import { useMergedSearchParams } from '../../util/use-merged-search-params/use-merged-search-params';
import DataTableState, { DataTableFilter, DataTableSort } from './data-table-state';

// hook to keep data table state in URL

export default function useDataTableState(defaultState?: Partial<DataTableState>) {
  const [searchParams, setSearchParams] = useMergedSearchParams(['page', 'size', 'sort', 'filter', 'filterLabel']);
  const defaultStateRef = React.useRef<DataTableState>({
    page: 0,
    size: 20,
    filter: [],
    sort: [],
    selection: [],
    ...defaultState,
  });
  const [selection, setSelection] = React.useState(defaultState?.selection ?? []);

  const state: DataTableState = React.useMemo(() => {
    const defaultState = defaultStateRef.current;
    const stateOverwritten = searchParams.has('page') || searchParams.has('size');

    return {
      page: Number(searchParams.get('page') ?? defaultState.page),
      size: Number(searchParams.get('size') ?? defaultState.size),
      sort:
        stateOverwritten || searchParams.has('sort')
          ? sortFromSearchParams(searchParams.getAll('sort'))
          : defaultState.sort,
      filter:
        stateOverwritten || searchParams.has('filter')
          ? filterFromSearchParams(searchParams.getAll('filter'), searchParams.getAll('filterLabel'))
          : defaultState.filter,
      selection: selection,
    };
  }, [searchParams, selection]);

  const patchState = useCallbackRef((patchedState: Partial<DataTableState>) => {
    const nextSearchParams = new URLSearchParams();
    const nextState = { ...state, ...patchedState };

    nextSearchParams.set('page', String(nextState.page));
    nextSearchParams.set('size', String(nextState.size));

    sortsToSearchParams(nextState.sort).forEach((sort) => nextSearchParams.append('sort', sort));
    filtersToSearchParams(nextState.filter).forEach((filter) => nextSearchParams.append('filter', filter));
    filterLabelsToSearchParams(nextState.filter).forEach((filterLabel) =>
      nextSearchParams.append('filterLabel', filterLabel),
    );

    setSelection(nextState.selection);

    // Only replace search params if they have changed. Prevents a lot of unnecessary re-renders.
    if (searchParams.toString() !== nextSearchParams.toString()) {
      setSearchParams(nextSearchParams, { replace: true });
    }
  });

  return [state, patchState] as const;
}

export function sortFromSearchParams(searchParams: string[]) {
  return searchParams.map((sort) => {
    const [property, direction] = sort.split(',');
    return { property, direction } as DataTableSort;
  });
}

export function sortsToSearchParams(sorts: DataTableSort[]) {
  return sorts.map(sortToSearchParams);
}

export function sortToSearchParams(sort: DataTableSort) {
  return `${sort.property},${sort.direction}`;
}

export function filterFromSearchParams(filterSearchParams: string[], filterLabelSearchParams: string[]) {
  const filterLabels = filterLabelSearchParams.reduce<Record<string, string>>((labels, filterLabelSearchParam) => {
    const [property, ...label] = filterLabelSearchParam.split(',');
    labels[property] = label.join(',');

    return labels;
  }, {});

  return filterSearchParams.map((filterSearchParam) => {
    const [property, operator, ...values] = filterSearchParam.split(',');
    return { property, operator, value: values.join(','), label: filterLabels[property] } satisfies DataTableFilter;
  });
}

export function filtersToSearchParams(filters: DataTableFilter[]) {
  return filters.map((filter) => filterToSearchParams(filter));
}

export function filterLabelsToSearchParams(filters: DataTableFilter[]) {
  return filters.filter((filter) => filter.label != null).map((filter) => filterLabelToSearchParams(filter));
}

export function filterToSearchParams(filter: DataTableFilter) {
  return `${filter.property},${filter.operator},${filter.value}`;
}

export function filterLabelToSearchParams(filter: DataTableFilter) {
  return `${filter.property},${filter.label}`;
}
