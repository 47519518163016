/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { FilmPersonRelationCategoryDto } from './FilmPersonRelationCategoryDto';
import {
    FilmPersonRelationCategoryDtoFromJSON,
    FilmPersonRelationCategoryDtoFromJSONTyped,
    FilmPersonRelationCategoryDtoToJSON,
} from './FilmPersonRelationCategoryDto';
import type { FilmPersonRelationTypeDto } from './FilmPersonRelationTypeDto';
import {
    FilmPersonRelationTypeDtoFromJSON,
    FilmPersonRelationTypeDtoFromJSONTyped,
    FilmPersonRelationTypeDtoToJSON,
} from './FilmPersonRelationTypeDto';
import type { FilmReferenceDto } from './FilmReferenceDto';
import {
    FilmReferenceDtoFromJSON,
    FilmReferenceDtoFromJSONTyped,
    FilmReferenceDtoToJSON,
} from './FilmReferenceDto';

/**
 * 
 * @export
 * @interface CreateFilmPersonRelationDto
 */
export interface CreateFilmPersonRelationDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CreateFilmPersonRelationDto
     */
    personId: string;
    /**
     * 
     * @type {Array<FilmReferenceDto>}
     * @memberof CreateFilmPersonRelationDto
     */
    films: Array<FilmReferenceDto>;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof CreateFilmPersonRelationDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {FilmPersonRelationCategoryDto}
     * @memberof CreateFilmPersonRelationDto
     */
    relationCategory: FilmPersonRelationCategoryDto;
    /**
     * 
     * @type {FilmPersonRelationTypeDto}
     * @memberof CreateFilmPersonRelationDto
     */
    relationType: FilmPersonRelationTypeDto;
}

/**
 * Check if a given object implements the CreateFilmPersonRelationDto interface.
 */
export function instanceOfCreateFilmPersonRelationDto(value: object): boolean {
    if (!('personId' in value)) return false;
    if (!('films' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('relationCategory' in value)) return false;
    if (!('relationType' in value)) return false;
    return true;
}

export function CreateFilmPersonRelationDtoFromJSON(json: any): CreateFilmPersonRelationDto {
    return CreateFilmPersonRelationDtoFromJSONTyped(json, false);
}

export function CreateFilmPersonRelationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateFilmPersonRelationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'personId': json['personId'],
        'films': ((json['films'] as Array<any>).map(FilmReferenceDtoFromJSON)),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'relationCategory': FilmPersonRelationCategoryDtoFromJSON(json['relationCategory']),
        'relationType': FilmPersonRelationTypeDtoFromJSON(json['relationType']),
    };
}

export function CreateFilmPersonRelationDtoToJSON(value?: CreateFilmPersonRelationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'personId': value['personId'],
        'films': ((value['films'] as Array<any>).map(FilmReferenceDtoToJSON)),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'relationCategory': FilmPersonRelationCategoryDtoToJSON(value['relationCategory']),
        'relationType': FilmPersonRelationTypeDtoToJSON(value['relationType']),
    };
}

