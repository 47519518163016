import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { OwnerDto, OwnerTypeDto } from '../../../api';
import { ElementListControl } from '../../../ui/form/element-control';
import { LayoutType } from '../layout-type';
import OwnerFormModal from './owner-form-modal';

interface OwnersControlProps {
  layout: LayoutType;
  ownerTypeHelperText?: React.ReactNode;
  ownersHelperText?: string;
}

export default function OwnersControl({ layout, ownerTypeHelperText, ownersHelperText }: OwnersControlProps) {
  const { t: tCommon } = useTranslation('common');
  const { watch } = useFormContext();
  const addedOwners = watch('owners');
  return (
    <ElementListControl<OwnerDto>
      isRequired
      validate={(value: OwnerDto[]) => {
        const nonCoOwner = value.filter((owner) => owner.type === OwnerTypeDto.OWNER);
        const isValid = nonCoOwner.length >= 1;
        return isValid ? undefined : tCommon('owners.validation_error.no_owner');
      }}
      layout={layout}
      name="owners"
      label={tCommon('owners.label')}
      addLabel={tCommon('owners.add_label')}
      editLabel={tCommon('owners.edit_label')}
      deleteLabel={tCommon('owners.delete_label')}
      renderDeleteLabel={(owner) =>
        tCommon('owners.delete_label', {
          ownertype: tCommon(`owners.typeLabels.${owner.type}`),
          owner: owner.section?.name,
        })
      }
      renderDeleteMessage={(owner) => (
        <Trans
          t={tCommon}
          i18nKey="owners.delete_message"
          values={{ ownertype: tCommon(`owners.typeLabels.${owner.type}`), owner: owner.section?.name }}
        />
      )}
      renderElement={(owner) => (
        <Box>
          <Text as="span">{owner.section?.name}</Text>{' '}
          <Text as="span" color="text.muted">
            ({tCommon(`owners.typeLabels.${owner.type!}`)})
          </Text>
        </Box>
      )}
      formModal={<OwnerFormModal addedOwners={addedOwners} ownerTypeHelperText={ownerTypeHelperText} />}
      helperText={ownersHelperText == null ? tCommon('owners.helper_text') : ownersHelperText}
    />
  );
}
