/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { FilmPersonRelationTypeDto } from './FilmPersonRelationTypeDto';
import {
    FilmPersonRelationTypeDtoFromJSON,
    FilmPersonRelationTypeDtoFromJSONTyped,
    FilmPersonRelationTypeDtoToJSON,
} from './FilmPersonRelationTypeDto';

/**
 * Film relations by person
 * @export
 * @interface PersonFilmListItemDto
 */
export interface PersonFilmListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonFilmListItemDto
     */
    id: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof PersonFilmListItemDto
     */
    filmId: string;
    /**
     * The original title of the film.
     * @type {string}
     * @memberof PersonFilmListItemDto
     */
    originalTitle: string;
    /**
     * The english title of the film.
     * @type {string}
     * @memberof PersonFilmListItemDto
     */
    englishTitle: string;
    /**
     * 
     * @type {FilmPersonRelationTypeDto}
     * @memberof PersonFilmListItemDto
     */
    relationType: FilmPersonRelationTypeDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof PersonFilmListItemDto
     */
    edition: EditionReferenceDto;
}

/**
 * Check if a given object implements the PersonFilmListItemDto interface.
 */
export function instanceOfPersonFilmListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('filmId' in value)) return false;
    if (!('originalTitle' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('edition' in value)) return false;
    return true;
}

export function PersonFilmListItemDtoFromJSON(json: any): PersonFilmListItemDto {
    return PersonFilmListItemDtoFromJSONTyped(json, false);
}

export function PersonFilmListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonFilmListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filmId': json['filmId'],
        'originalTitle': json['originalTitle'],
        'englishTitle': json['englishTitle'],
        'relationType': FilmPersonRelationTypeDtoFromJSON(json['relationType']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
    };
}

export function PersonFilmListItemDtoToJSON(value?: PersonFilmListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'filmId': value['filmId'],
        'originalTitle': value['originalTitle'],
        'englishTitle': value['englishTitle'],
        'relationType': FilmPersonRelationTypeDtoToJSON(value['relationType']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
    };
}

