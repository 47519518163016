/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { MailingAnchorModuleSettingsDto } from './MailingAnchorModuleSettingsDto';
import {
    instanceOfMailingAnchorModuleSettingsDto,
    MailingAnchorModuleSettingsDtoFromJSON,
    MailingAnchorModuleSettingsDtoFromJSONTyped,
    MailingAnchorModuleSettingsDtoToJSON,
} from './MailingAnchorModuleSettingsDto';
import type { MailingCodeConfigModuleSettingsDto } from './MailingCodeConfigModuleSettingsDto';
import {
    instanceOfMailingCodeConfigModuleSettingsDto,
    MailingCodeConfigModuleSettingsDtoFromJSON,
    MailingCodeConfigModuleSettingsDtoFromJSONTyped,
    MailingCodeConfigModuleSettingsDtoToJSON,
} from './MailingCodeConfigModuleSettingsDto';
import type { MailingCodeModuleSettingsDto } from './MailingCodeModuleSettingsDto';
import {
    instanceOfMailingCodeModuleSettingsDto,
    MailingCodeModuleSettingsDtoFromJSON,
    MailingCodeModuleSettingsDtoFromJSONTyped,
    MailingCodeModuleSettingsDtoToJSON,
} from './MailingCodeModuleSettingsDto';
import type { MailingDynamicTextModuleSettingsDto } from './MailingDynamicTextModuleSettingsDto';
import {
    instanceOfMailingDynamicTextModuleSettingsDto,
    MailingDynamicTextModuleSettingsDtoFromJSON,
    MailingDynamicTextModuleSettingsDtoFromJSONTyped,
    MailingDynamicTextModuleSettingsDtoToJSON,
} from './MailingDynamicTextModuleSettingsDto';
import type { MailingFlexModuleSettingsDto } from './MailingFlexModuleSettingsDto';
import {
    instanceOfMailingFlexModuleSettingsDto,
    MailingFlexModuleSettingsDtoFromJSON,
    MailingFlexModuleSettingsDtoFromJSONTyped,
    MailingFlexModuleSettingsDtoToJSON,
} from './MailingFlexModuleSettingsDto';
import type { MailingOfferModuleSettingsDto } from './MailingOfferModuleSettingsDto';
import {
    instanceOfMailingOfferModuleSettingsDto,
    MailingOfferModuleSettingsDtoFromJSON,
    MailingOfferModuleSettingsDtoFromJSONTyped,
    MailingOfferModuleSettingsDtoToJSON,
} from './MailingOfferModuleSettingsDto';
import type { MailingPictureModuleSettingsDto } from './MailingPictureModuleSettingsDto';
import {
    instanceOfMailingPictureModuleSettingsDto,
    MailingPictureModuleSettingsDtoFromJSON,
    MailingPictureModuleSettingsDtoFromJSONTyped,
    MailingPictureModuleSettingsDtoToJSON,
} from './MailingPictureModuleSettingsDto';
import type { MailingPortraitModuleSettingsDto } from './MailingPortraitModuleSettingsDto';
import {
    instanceOfMailingPortraitModuleSettingsDto,
    MailingPortraitModuleSettingsDtoFromJSON,
    MailingPortraitModuleSettingsDtoFromJSONTyped,
    MailingPortraitModuleSettingsDtoToJSON,
} from './MailingPortraitModuleSettingsDto';
import type { MailingRsvpModuleSettingsDto } from './MailingRsvpModuleSettingsDto';
import {
    instanceOfMailingRsvpModuleSettingsDto,
    MailingRsvpModuleSettingsDtoFromJSON,
    MailingRsvpModuleSettingsDtoFromJSONTyped,
    MailingRsvpModuleSettingsDtoToJSON,
} from './MailingRsvpModuleSettingsDto';
import type { MailingSalutationModuleSettingsDto } from './MailingSalutationModuleSettingsDto';
import {
    instanceOfMailingSalutationModuleSettingsDto,
    MailingSalutationModuleSettingsDtoFromJSON,
    MailingSalutationModuleSettingsDtoFromJSONTyped,
    MailingSalutationModuleSettingsDtoToJSON,
} from './MailingSalutationModuleSettingsDto';
import type { MailingTextModuleSettingsDto } from './MailingTextModuleSettingsDto';
import {
    instanceOfMailingTextModuleSettingsDto,
    MailingTextModuleSettingsDtoFromJSON,
    MailingTextModuleSettingsDtoFromJSONTyped,
    MailingTextModuleSettingsDtoToJSON,
} from './MailingTextModuleSettingsDto';
import type { MailingTicketModuleSettingsDto } from './MailingTicketModuleSettingsDto';
import {
    instanceOfMailingTicketModuleSettingsDto,
    MailingTicketModuleSettingsDtoFromJSON,
    MailingTicketModuleSettingsDtoFromJSONTyped,
    MailingTicketModuleSettingsDtoToJSON,
} from './MailingTicketModuleSettingsDto';
import type { MailingTicketWithoutPlacementModuleSettingsDto } from './MailingTicketWithoutPlacementModuleSettingsDto';
import {
    instanceOfMailingTicketWithoutPlacementModuleSettingsDto,
    MailingTicketWithoutPlacementModuleSettingsDtoFromJSON,
    MailingTicketWithoutPlacementModuleSettingsDtoFromJSONTyped,
    MailingTicketWithoutPlacementModuleSettingsDtoToJSON,
} from './MailingTicketWithoutPlacementModuleSettingsDto';

/**
 * @type MailingModuleSettingsDto
 * 
 * @export
 */
export type MailingModuleSettingsDto = { moduleType: 'ANCHOR' } & MailingAnchorModuleSettingsDto | { moduleType: 'CODE' } & MailingCodeModuleSettingsDto | { moduleType: 'CODE_CONFIG' } & MailingCodeConfigModuleSettingsDto | { moduleType: 'DYNAMIC_TEXT' } & MailingDynamicTextModuleSettingsDto | { moduleType: 'FLEX' } & MailingFlexModuleSettingsDto | { moduleType: 'OFFER' } & MailingOfferModuleSettingsDto | { moduleType: 'PICTURE' } & MailingPictureModuleSettingsDto | { moduleType: 'PORTRAIT' } & MailingPortraitModuleSettingsDto | { moduleType: 'RSVP' } & MailingRsvpModuleSettingsDto | { moduleType: 'SALUTATION' } & MailingSalutationModuleSettingsDto | { moduleType: 'TEXT' } & MailingTextModuleSettingsDto | { moduleType: 'TICKET' } & MailingTicketModuleSettingsDto | { moduleType: 'TICKET_WITHOUT_PLACEMENT' } & MailingTicketWithoutPlacementModuleSettingsDto;

export function MailingModuleSettingsDtoFromJSON(json: any): MailingModuleSettingsDto {
    return MailingModuleSettingsDtoFromJSONTyped(json, false);
}

export function MailingModuleSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailingModuleSettingsDto {
    if (json == null) {
        return json;
    }
    switch (json['moduleType']) {
        case 'ANCHOR':
            return {...MailingAnchorModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'ANCHOR'};
        case 'CODE':
            return {...MailingCodeModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'CODE'};
        case 'CODE_CONFIG':
            return {...MailingCodeConfigModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'CODE_CONFIG'};
        case 'DYNAMIC_TEXT':
            return {...MailingDynamicTextModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'DYNAMIC_TEXT'};
        case 'FLEX':
            return {...MailingFlexModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'FLEX'};
        case 'OFFER':
            return {...MailingOfferModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'OFFER'};
        case 'PICTURE':
            return {...MailingPictureModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'PICTURE'};
        case 'PORTRAIT':
            return {...MailingPortraitModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'PORTRAIT'};
        case 'RSVP':
            return {...MailingRsvpModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'RSVP'};
        case 'SALUTATION':
            return {...MailingSalutationModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'SALUTATION'};
        case 'TEXT':
            return {...MailingTextModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'TEXT'};
        case 'TICKET':
            return {...MailingTicketModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'TICKET'};
        case 'TICKET_WITHOUT_PLACEMENT':
            return {...MailingTicketWithoutPlacementModuleSettingsDtoFromJSONTyped(json, true), moduleType: 'TICKET_WITHOUT_PLACEMENT'};
        default:
            throw new Error(`No variant of MailingModuleSettingsDto exists with 'moduleType=${json['moduleType']}'`);
    }
}

export function MailingModuleSettingsDtoToJSON(value?: MailingModuleSettingsDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['moduleType']) {
        case 'ANCHOR':
            return MailingAnchorModuleSettingsDtoToJSON(value);
        case 'CODE':
            return MailingCodeModuleSettingsDtoToJSON(value);
        case 'CODE_CONFIG':
            return MailingCodeConfigModuleSettingsDtoToJSON(value);
        case 'DYNAMIC_TEXT':
            return MailingDynamicTextModuleSettingsDtoToJSON(value);
        case 'FLEX':
            return MailingFlexModuleSettingsDtoToJSON(value);
        case 'OFFER':
            return MailingOfferModuleSettingsDtoToJSON(value);
        case 'PICTURE':
            return MailingPictureModuleSettingsDtoToJSON(value);
        case 'PORTRAIT':
            return MailingPortraitModuleSettingsDtoToJSON(value);
        case 'RSVP':
            return MailingRsvpModuleSettingsDtoToJSON(value);
        case 'SALUTATION':
            return MailingSalutationModuleSettingsDtoToJSON(value);
        case 'TEXT':
            return MailingTextModuleSettingsDtoToJSON(value);
        case 'TICKET':
            return MailingTicketModuleSettingsDtoToJSON(value);
        case 'TICKET_WITHOUT_PLACEMENT':
            return MailingTicketWithoutPlacementModuleSettingsDtoToJSON(value);
        default:
            throw new Error(`No variant of MailingModuleSettingsDto exists with 'moduleType=${value['moduleType']}'`);
    }

}

