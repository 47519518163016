import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../util/swr/use-fetcher';
import { fetchMailingList } from './mailing-list-queries';

export default function MailingListBreadcrumb() {
  const params = useParams<{ listId: string }>();
  const mailingList = useFetcher(fetchMailingList, { id: params.listId! });
  const breadcrumb = mailingList.title;

  return <>{breadcrumb}</>;
}
