import { HStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionItem } from '../../../ui/description';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchOffer } from '../offer-queries';

export interface OfferMailingModuleControlProps {
  offerId: string;
}

export default function OfferMailingModuleControl({ offerId }: OfferMailingModuleControlProps) {
  const { t } = useTranslation(['offer']);

  const offer = useFetcher(fetchOffer, { id: offerId });

  return (
    <HStack spacing={6} alignItems="top">
      <DescriptionItem label={t('offer:offerNumber')}>{offer.offerNumber}</DescriptionItem>
    </HStack>
  );
}
