import { Text, Wrap, WrapItem } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { OrderEditorViewItemDto, OrderListItemDto, OrderStatusDto } from '../../../api';
import accessGroupApi from '../../../data-access/access-group-api';
import formatApi from '../../../data-access/format-api';
import orderApi from '../../../data-access/order-api';
import productApi from '../../../data-access/product-api';
import productBundleApi from '../../../data-access/product-bundle-api';
import { DataTableColumn, DataTableLink, DataTableTruncatedText } from '../../../ui/data-table';
import DateFilter from '../../../ui/data-table/filter/date-filter';
import EnumFilter from '../../../ui/data-table/filter/enum-filter';
import InFilter from '../../../ui/data-table/filter/in-filter';
import StringFilter from '../../../ui/data-table/filter/string-filter';
import Optional from '../../../ui/optional/optional';
import LastModifiedLabel from '../../../ui/version/last-modified-label';
import Translate from '../../../util/translate/translate';
import AccessGroupReference from '../../access-group/access-group-reference/access-group-reference';
import CompanyReference from '../../company/company-reference/company-reference';
import FormatReference from '../../format/format-reference/format-reference';
import HasPermission from '../../permission/has-permission';
import { PROFESSION_OPTIONS } from '../../person/person-enum-constants';
import PersonReference from '../../person/person-reference/person-reference';
import ProductBundleReference from '../../product-bundle/product-bundle-reference';
import ProductReference from '../../product/product-reference/product-reference';
import { paymentOptions, statusOptions } from '../order-enum-constants';

export default function useOrderTableColumns(linkPath: string = '') {
  const { t } = useTranslation(['common', 'order', 'person']);
  return React.useMemo(() => {
    const columns: DataTableColumn<OrderListItemDto>[] = [
      {
        key: 'orderNumber',
        sticky: true,
        name: t('order:orderNumber'),
        cellProps: {
          whiteSpace: 'nowrap',
          width: '10%',
        },
        isSortable: true,
        renderCell: (order) => (
          <HasPermission necessaryPermission="ORDER:CAN_SEE_DETAILS" fallback={order.orderNumber}>
            <DataTableTruncatedText>
              <DataTableLink as={RouterLink} to={`${linkPath}${order.id!}`}>
                <Text color={getColorByStatus(order.status)}>{order.orderNumber}</Text>
              </DataTableLink>
            </DataTableTruncatedText>
          </HasPermission>
        ),
        filter: (
          <StringFilter
            label={t('order:orderNumber')}
            loadOptions={async (value: string) => {
              const page = await orderApi.searchOrders({
                pageable: { size: 10 },
                filter: [`orderNumber,contain,${value}`],
              });
              return page.content.map((order) => order.orderNumber);
            }}
          />
        ),
      },
      {
        key: 'buyer.filterField',
        name: t('order:buyer'),
        isSortable: true,
        sortProperty: 'buyer.surname',
        renderCell: (order) => (
          <DataTableTruncatedText>
            <PersonReference
              personReference={order.buyer}
              hidePersonKey
              isTruncated
              usePortalForCard
              flipName
              color={getColorByStatus(order.status)}
            />
          </DataTableTruncatedText>
        ),
        filter: <StringFilter label={t('order:buyer')} />,
      },
      {
        key: 'buyerCompany',
        name: t('person:occupations.company'),
        isSortable: true,
        sortProperty: 'buyerCompany.name',
        renderCell: (order) => (
          <Optional isEmpty={order.buyerCompany == null}>
            <CompanyReference companyReference={order.buyerCompany} color={getColorByStatus(order.status)} />
          </Optional>
        ),
        filter: <StringFilter label={t('person:occupations.company')} />,
      },
      {
        key: 'buyerFormats',
        filterProperty: 'buyerFormats[*].id',
        name: t('order:formats'),
        renderCell: (order) => (
          <Optional>
            {!isEmpty(order.buyerFormats) && (
              <Wrap spacingX={4} spacingY={2}>
                {order.buyerFormats!.map((format, idx) => (
                  <WrapItem key={idx}>
                    <FormatReference format={format} />
                  </WrapItem>
                ))}
              </Wrap>
            )}
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="order" i18nKey="formats" />}
            loadOptions={async (query: string) => {
              const formats = await formatApi.searchFormats({ filter: [`name,contain,${query}`] });

              return formats.content.map((format) => ({
                label: format.name,
                value: format.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'buyerProfession',
        name: t('person:occupations.profession'),
        isSortable: true,
        renderCell: (order) => (
          <Optional isEmpty={order.buyerProfession == null}>
            <Text color={getColorByStatus(order.status)}>
              {t(`person:occupations.professionOptions.${order.buyerProfession!}`)}
            </Text>
          </Optional>
        ),
        filter: (
          <EnumFilter
            label={t('person:occupations.profession')}
            options={PROFESSION_OPTIONS}
            renderOptionLabel={(key) => t(`person:occupations.professionOptions.${key}`)}
          />
        ),
      },
      {
        key: 'products',
        filterProperty: 'products[*].id',
        name: <Translate ns="order" i18nKey="products" />,
        renderCell: (order) => (
          <Optional isEmpty={order.products.length === 0}>
            <Wrap spacingX={4} spacingY={2}>
              {order.products.map((product, idx) => (
                <WrapItem key={idx}>
                  <ProductReference productReference={product} color={getColorByStatus(order.status)} />
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="order" i18nKey="products" />}
            loadOptions={async (query: string) => {
              const products = await productApi.searchProductReferences({ filter: [`title,contain,${query}`] });

              return products.content.map((product) => ({
                label: product.title,
                value: product.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'productBundles',
        filterProperty: 'productBundles[*].id',
        name: <Translate ns="order" i18nKey="productBundles" />,
        renderCell: (order) => (
          <Optional isEmpty={order.productBundles.length === 0}>
            <Wrap spacingX={4} spacingY={2}>
              {order.productBundles.map((bundle, idx) => (
                <WrapItem key={idx}>
                  <ProductBundleReference productBundleReference={bundle} color={getColorByStatus(order.status)} />
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="order" i18nKey="productBundles" />}
            loadOptions={async (query: string) => {
              const productBundles = await productBundleApi.searchProductBundleReferences({
                q: query,
              });

              return productBundles.content.map((bundle) => ({
                label: bundle.title,
                value: bundle.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'accessGroups',
        filterProperty: 'accessGroups[*].id',
        name: <Translate ns="order" i18nKey="accessGroups" />,
        renderCell: (order) => (
          <Optional isEmpty={order.accessGroups.length === 0}>
            <Wrap spacingX={4} spacingY={2}>
              {order.accessGroups.map((accessGroup) => (
                <WrapItem key={accessGroup.id}>
                  <AccessGroupReference accessGroup={accessGroup} color={getColorByStatus(order.status)} />
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
        filter: (
          <InFilter
            label={<Translate ns="product" i18nKey="accessGroups" />}
            loadOptions={async (query: string) => {
              const accessGroups = await accessGroupApi.searchAccessGroupListItems({
                filter: [`title,contain,${query}`],
              });

              return accessGroups.content.map((accessGroup) => ({
                label: accessGroup.title,
                value: accessGroup.id!,
              }));
            }}
          />
        ),
      },
      {
        key: 'status',
        name: t('order:status'),
        isSortable: true,
        renderCell: (order) => (
          <Text color={getColorByStatus(order.status)}>{t(`order:statusOptions.${order.status}`)}</Text>
        ),
        filter: (
          <EnumFilter
            label={t('order:status')}
            options={statusOptions}
            renderOptionLabel={(key) => t(`order:statusOptions.${key}`)}
          />
        ),
      },
      {
        key: 'paymentStatus',
        name: t('order:paymentStatus'),
        isSortable: true,
        renderCell: (order) => (
          <Text color={getColorByStatus(order.status)}>{t(`order:paymentOptions.${order.paymentStatus}`)}</Text>
        ),
        filter: (
          <EnumFilter
            label={t('order:paymentStatus')}
            options={paymentOptions}
            renderOptionLabel={(key) => t(`order:paymentOptions.${key}`)}
          />
        ),
      },
      {
        key: 'comment',
        name: t('order:comment'),
        isSortable: true,
        renderCell: (order) => (
          <Optional isEmpty={order.comment == null}>
            <Text color={getColorByStatus(order.status)}>{order.comment}</Text>
          </Optional>
        ),
        filter: <StringFilter label={t('order:comment')} />,
      },
      {
        key: 'createdAt',
        name: t('order:createdAt'),
        renderCell: (order) => (
          <Text color={getColorByStatus(order.status)}>{t('common:format.date', { date: order.createdAt })}</Text>
        ),
        isSortable: true,
        sortProperty: 'createdAt',
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
      {
        key: 'modifiedAt',
        name: t('common:viewer.last_modification'),
        renderCell: (order) => <LastModifiedLabel version={order.version} color={getColorByStatus(order.status)} />,
        isSortable: true,
        filter: <DateFilter showTimeSelect />,
        filterWidth: 'md',
      },
    ];

    return columns;
  }, [linkPath, t]);
}

export function useOrderEditorTableColumns() {
  const { t } = useTranslation(['common', 'order', 'person']);
  return React.useMemo(() => {
    const columns: DataTableColumn<OrderEditorViewItemDto>[] = [
      {
        key: 'edition',
        name: <Translate ns="edition" i18nKey="edition" />,
        renderCell: (order) => order.edition.name,
      },
      {
        key: 'products',
        name: <Translate ns="order" i18nKey="products" />,
        renderCell: (order) => (
          <Optional isEmpty={order.products.length === 0}>
            <Wrap spacingX={4} spacingY={2}>
              {order.products.map((product, index) => (
                <WrapItem key={index}>
                  <ProductReference productReference={product} size="sm" />
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
      },
      {
        key: 'productBundles',
        name: <Translate ns="order" i18nKey="productBundles" />,
        renderCell: (order) => (
          <Optional isEmpty={order.productBundles.length === 0}>
            <Wrap spacingX={4} spacingY={2}>
              {order.productBundles.map((bundle, index) => (
                <WrapItem key={index}>
                  <ProductBundleReference productBundleReference={bundle} size="sm" />
                </WrapItem>
              ))}
            </Wrap>
          </Optional>
        ),
      },
      {
        key: 'accessGroups',
        name: <Translate ns="order" i18nKey="accessGroups" />,
        renderCell: (viewerItem) => (
          <Wrap>
            {viewerItem.accessGroups
              .sort((a, b) => a.title!.localeCompare(b.title!))
              .map((accessGroup) => (
                <WrapItem key={accessGroup.id}>
                  <AccessGroupReference accessGroup={accessGroup} size="sm" />
                </WrapItem>
              ))}
          </Wrap>
        ),
      },
      {
        key: 'status',
        name: t('order:status'),
        renderCell: (order) => t(`order:statusOptions.${order.status}`),
      },
      {
        key: 'paymentStatus',
        name: t('order:paymentStatus'),
        renderCell: (order) => t(`order:paymentOptions.${order.paymentStatus}`),
      },
      {
        key: 'createdAt',
        name: t('order:createdAt'),
        renderCell: (order) => t('common:format.date', { date: order.createdAt }),
      },
    ];

    return columns;
  }, [t]);
}

function getColorByStatus(status: OrderStatusDto) {
  return status === OrderStatusDto.BLOCKED ? 'text.muted' : undefined;
}
