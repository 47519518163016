/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupReferenceDto
 */
export interface GroupReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupReferenceDto
     */
    name: string;
    /**
     * Only section ids of group owners with type owner are included
     * @type {Array<string>}
     * @memberof GroupReferenceDto
     */
    ownerSectionIds: Array<string>;
    /**
     * Only section ids of group co-owners with type owner are included
     * @type {Array<string>}
     * @memberof GroupReferenceDto
     */
    coOwnerSectionIds: Array<string>;
}

/**
 * Check if a given object implements the GroupReferenceDto interface.
 */
export function instanceOfGroupReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('ownerSectionIds' in value)) return false;
    if (!('coOwnerSectionIds' in value)) return false;
    return true;
}

export function GroupReferenceDtoFromJSON(json: any): GroupReferenceDto {
    return GroupReferenceDtoFromJSONTyped(json, false);
}

export function GroupReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'ownerSectionIds': json['ownerSectionIds'],
        'coOwnerSectionIds': json['coOwnerSectionIds'],
    };
}

export function GroupReferenceDtoToJSON(value?: GroupReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'ownerSectionIds': value['ownerSectionIds'],
        'coOwnerSectionIds': value['coOwnerSectionIds'],
    };
}

