import React from 'react';
import MailingTypeExtension from '../../mailing/mailing-type-extension';
import NewsletterMailingRecipientEditor from './newsletter-mailing-recipient-editor';
import NewsletterMailingRecipientLister from './newsletter-mailing-recipient-lister';

export function createNewsletterMailing(): MailingTypeExtension {
  return {
    type: 'NEWSLETTER',
    recipientEditorAddon({ mailingId }) {
      return <NewsletterMailingRecipientEditor mailingId={mailingId} />;
    },
    recipientListerAddon({ mailingId }) {
      return <NewsletterMailingRecipientLister mailingId={mailingId} />;
    },
  };
}
