import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import MailingConnectionSelect from '../../mailing/create-mailing-modal/mailing-connection-select';

export function OfferMailingConnectionSelect() {
  const { t } = useTranslation(['offer']);

  return (
    <MailingConnectionSelect
      label={t('offer:offer')}
      helperPopover={
        <HelperPopover header={t('offer:offer')}>
          <Trans t={t} i18nKey="offer:mailingExtension.helperText" />
        </HelperPopover>
      }
    />
  );
}
