import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import PageForm from '../../../ui/page/page-form';
import useMailingRecipientEditor from '../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';
import MailingListMailingRecipientControl, {
  AddMailingListRecipients,
} from '../mailing-list-recipient/mailing-list-mailing-recipient-control';

interface NewsletterMailingRecipientEditorProps {
  mailingId: string;
}

type FormType = AddMailingListRecipients;

export default function NewsletterMailingRecipientEditor({ mailingId }: NewsletterMailingRecipientEditorProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<FormType>(mailingId);

  const handleValid = async ({ mailingListRecipients }: FormType) => {
    await onValid({ recipients: mailingListRecipients });
  };

  return (
    <FormProvider {...form}>
      <PageForm onValid={handleValid} height="full" initialFocusRef={initialFocusRef}>
        <Stack spacing={4}>
          <MailingListMailingRecipientControl mailingId={mailingId} />
        </Stack>
      </PageForm>
    </FormProvider>
  );
}
