/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  PageableDto,
  PaymentDto,
  PaymentPageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PaymentDtoFromJSON,
    PaymentDtoToJSON,
    PaymentPageDtoFromJSON,
    PaymentPageDtoToJSON,
} from '../models/index';

export interface FetchPaymentRequest {
    id: string;
}

export interface SearchPaymentsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchPaymentsByOrderConfirmationRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SyncPaymentsByOrderConfirmationRequest {
    id: string;
}

/**
 * 
 */
export class PaymentTransactionApi extends runtime.BaseAPI {

    /**
     * Get the payment business object.
     */
    async fetchPaymentRaw(requestParameters: FetchPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-transactions/payments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDtoFromJSON(jsonValue));
    }

    /**
     * Get the payment business object.
     */
    async fetchPayment(requestParameters: FetchPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentDto> {
        const response = await this.fetchPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for paymnets.
     */
    async searchPaymentsRaw(requestParameters: SearchPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-transactions/payments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for paymnets.
     */
    async searchPayments(requestParameters: SearchPaymentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentPageDto> {
        const response = await this.searchPaymentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for payments by order confirmation.
     */
    async searchPaymentsByOrderConfirmationRaw(requestParameters: SearchPaymentsByOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchPaymentsByOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-transactions/payments/order-confirmation-payments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for payments by order confirmation.
     */
    async searchPaymentsByOrderConfirmation(requestParameters: SearchPaymentsByOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentPageDto> {
        const response = await this.searchPaymentsByOrderConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sync payments for an order confirmation.
     */
    async syncPaymentsByOrderConfirmationRaw(requestParameters: SyncPaymentsByOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling syncPaymentsByOrderConfirmation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-transactions/payments/order-confirmation-payments/{id}/sync`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sync payments for an order confirmation.
     */
    async syncPaymentsByOrderConfirmation(requestParameters: SyncPaymentsByOrderConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.syncPaymentsByOrderConfirmationRaw(requestParameters, initOverrides);
    }

}
