import React from 'react';
import { useTranslation } from 'react-i18next';
import { MailingDtoStatusEnum, RecipientLinkDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import ExistsFilter from '../../../ui/data-table/filter/exists-filter';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchMailing } from '../../mailing/mailing-queries';
import DeleteRecipientButton from '../../mailing/mailing-recipient-lister/delete-recipient-button';
import MailingListReference from '../mailing-list-reference/mailing-list-reference';
import { isMailingListRecipientLink } from './mailing-list-recipient-link';

export default function useMailingListRecipientColumns(mailingId: string) {
  const { t } = useTranslation('mailing');
  const mailing = useFetcher(fetchMailing, { id: mailingId });

  const columns: DataTableColumn<RecipientLinkDto>[] = React.useMemo(
    () => [
      {
        key: 'linkedRecipient.title',
        name: t('recipients.mailingList'),
        isSortable: true,
        renderCell: (recipientLink) =>
          isMailingListRecipientLink(recipientLink) && (
            <MailingListReference mailingList={recipientLink.linkedRecipient} />
          ),
        filter: <ExistsFilter label={t('recipients.mailingList')} />,
      },
      {
        key: 'action',
        sticky: true,
        renderCell: (recipientLink) =>
          isMailingListRecipientLink(recipientLink) && (
            <DeleteRecipientButton
              mailing={mailing}
              recipientId={recipientLink.id}
              recipientType="MAILING_LIST"
              isDisabled={mailing.status !== MailingDtoStatusEnum.DRAFT}
              displayName={recipientLink.linkedRecipient.title}
            />
          ),
      },
    ],
    [mailing, t],
  );
  return columns;
}
