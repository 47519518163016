import React from 'react';
import invariant from 'tiny-invariant';
import { TagRelationDto } from '../../../../api';
import HistoryPage from '../../../../ui/history/history-page';
import usePaginationState from '../../../../ui/pagination/use-pagination-state';
import useFetcher from '../../../../util/swr/use-fetcher';
import Translate from '../../../../util/translate/translate';
import { FilmHistoryExtension } from '../../../film/film-history/film-history-extension';
import { fetchTagHasRelationHistoryByRelation } from '../../tag-queries';
import useFilmTagHistorySettings from './use-film-tag-history-settings';

export const filmTagHistoryExtension: FilmHistoryExtension = {
  key: 'tags',
  buttonLabel: <Translate ns="tag" i18nKey="tag_other" />,
  content: (props) => <FilmTagHistory {...props} />,
};

function FilmTagHistory({ filmId }: { filmId: string }) {
  const [state, setState] = usePaginationState();
  const filmTagHistorySettings = useFilmTagHistorySettings();

  const filmTagHistoryPage = useFetcher(fetchTagHasRelationHistoryByRelation, {
    id: filmId,
    pageable: state,
    relationType: 'FILM',
  });

  invariant(filmTagHistoryPage != null, 'Missing film tag relation history page');

  return (
    <HistoryPage<TagRelationDto>
      page={filmTagHistoryPage}
      state={state}
      onStateChange={setState}
      historyDisplaySettings={filmTagHistorySettings}
      showLine={false}
    />
  );
}
