/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FilmPersonRelationTypeDto = {
    MAIN_ACTOR: 'MAIN_ACTOR',
    SUPPORTING_ACTOR: 'SUPPORTING_ACTOR',
    DIRECTOR: 'DIRECTOR',
    SCRIPT: 'SCRIPT',
    PRODUCTION: 'PRODUCTION',
    CO_PRODUCTION: 'CO_PRODUCTION',
    CAMERA: 'CAMERA',
    ASSEMBLY: 'ASSEMBLY',
    SOUND: 'SOUND',
    OTHERS: 'OTHERS',
    PR_AGENT: 'PR_AGENT',
    PUBLICIST: 'PUBLICIST',
    CONTACT: 'CONTACT'
} as const;
export type FilmPersonRelationTypeDto = typeof FilmPersonRelationTypeDto[keyof typeof FilmPersonRelationTypeDto];


export function FilmPersonRelationTypeDtoFromJSON(json: any): FilmPersonRelationTypeDto {
    return FilmPersonRelationTypeDtoFromJSONTyped(json, false);
}

export function FilmPersonRelationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmPersonRelationTypeDto {
    return json as FilmPersonRelationTypeDto;
}

export function FilmPersonRelationTypeDtoToJSON(value?: FilmPersonRelationTypeDto | null): any {
    return value as any;
}

