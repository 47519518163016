/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OfferListItemDto } from './OfferListItemDto';
import {
    OfferListItemDtoFromJSON,
    OfferListItemDtoFromJSONTyped,
    OfferListItemDtoToJSON,
} from './OfferListItemDto';

/**
 * 
 * @export
 * @interface OfferPageDto
 */
export interface OfferPageDto {
    /**
     * The number of the page.
     * @type {number}
     * @memberof OfferPageDto
     */
    page: number;
    /**
     * The size of the page.
     * @type {number}
     * @memberof OfferPageDto
     */
    size: number;
    /**
     * The total number of data returned by the search.
     * @type {number}
     * @memberof OfferPageDto
     */
    totalElements: number;
    /**
     * The total number of pages of current size to fit all data.
     * @type {number}
     * @memberof OfferPageDto
     */
    totalPages: number;
    /**
     * 
     * @type {Array<OfferListItemDto>}
     * @memberof OfferPageDto
     */
    content: Array<OfferListItemDto>;
}

/**
 * Check if a given object implements the OfferPageDto interface.
 */
export function instanceOfOfferPageDto(value: object): boolean {
    if (!('page' in value)) return false;
    if (!('size' in value)) return false;
    if (!('totalElements' in value)) return false;
    if (!('totalPages' in value)) return false;
    if (!('content' in value)) return false;
    return true;
}

export function OfferPageDtoFromJSON(json: any): OfferPageDto {
    return OfferPageDtoFromJSONTyped(json, false);
}

export function OfferPageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferPageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'content': ((json['content'] as Array<any>).map(OfferListItemDtoFromJSON)),
    };
}

export function OfferPageDtoToJSON(value?: OfferPageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'size': value['size'],
        'totalElements': value['totalElements'],
        'totalPages': value['totalPages'],
        'content': ((value['content'] as Array<any>).map(OfferListItemDtoToJSON)),
    };
}

