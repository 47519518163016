/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateFilmPersonRelationDto,
  FailureDto,
  FilmDto,
  FilmPageDto,
  FilmPersonPageDto,
  FilmReferencePageDto,
  HistoryPageDto,
  PageableDto,
  PersonFilmPageDto,
} from '../models/index';
import {
    CreateFilmPersonRelationDtoFromJSON,
    CreateFilmPersonRelationDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    FilmDtoFromJSON,
    FilmDtoToJSON,
    FilmPageDtoFromJSON,
    FilmPageDtoToJSON,
    FilmPersonPageDtoFromJSON,
    FilmPersonPageDtoToJSON,
    FilmReferencePageDtoFromJSON,
    FilmReferencePageDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PersonFilmPageDtoFromJSON,
    PersonFilmPageDtoToJSON,
} from '../models/index';

export interface CreateFilmPersonRelationsByPersonRequest {
    createFilmPersonRelationDto: CreateFilmPersonRelationDto;
}

export interface DeleteFilmPersonRelationRequest {
    relationId: string;
}

export interface FetchFilmRequest {
    id: string;
}

export interface FetchFilmHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchHistoryByFilmRequest {
    id: string;
    pageable?: PageableDto;
}

export interface PersonFilmRelationsExistRequest {
    personId: string;
    filmIds: Array<string>;
}

export interface SearchConfirmedFilmReferencesRequest {
    q?: string;
    country?: string;
    editionId?: string;
    pageable?: PageableDto;
}

export interface SearchFilmListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchFilmPersonRelationByFilmRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchFilmPersonRelationByPersonRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

/**
 * 
 */
export class FilmApi extends runtime.BaseAPI {

    /**
     * Create a person film relation.
     */
    async createFilmPersonRelationsByPersonRaw(requestParameters: CreateFilmPersonRelationsByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createFilmPersonRelationDto'] == null) {
            throw new runtime.RequiredError(
                'createFilmPersonRelationDto',
                'Required parameter "createFilmPersonRelationDto" was null or undefined when calling createFilmPersonRelationsByPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/film-person-relations/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFilmPersonRelationDtoToJSON(requestParameters['createFilmPersonRelationDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a person film relation.
     */
    async createFilmPersonRelationsByPerson(requestParameters: CreateFilmPersonRelationsByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createFilmPersonRelationsByPersonRaw(requestParameters, initOverrides);
    }

    /**
     * Delete film-person relations.
     */
    async deleteFilmPersonRelationRaw(requestParameters: DeleteFilmPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling deleteFilmPersonRelation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['relationId'] != null) {
            queryParameters['relationId'] = requestParameters['relationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/film-person-relations/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete film-person relations.
     */
    async deleteFilmPersonRelation(requestParameters: DeleteFilmPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFilmPersonRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch a single Film by id
     */
    async fetchFilmRaw(requestParameters: FetchFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFilm().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmDtoFromJSON(jsonValue));
    }

    /**
     * Fetch a single Film by id
     */
    async fetchFilm(requestParameters: FetchFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmDto> {
        const response = await this.fetchFilmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the film.
     */
    async fetchFilmHistoryRaw(requestParameters: FetchFilmHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchFilmHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the film.
     */
    async fetchFilmHistory(requestParameters: FetchFilmHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchFilmHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fetchHistoryByFilmRaw(requestParameters: FetchHistoryByFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchHistoryByFilm().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/film-person-relations/versions-by-film/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async fetchHistoryByFilm(requestParameters: FetchHistoryByFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchHistoryByFilmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check for existing relations between person and given film ids
     */
    async personFilmRelationsExistRaw(requestParameters: PersonFilmRelationsExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling personFilmRelationsExist().'
            );
        }

        if (requestParameters['filmIds'] == null) {
            throw new runtime.RequiredError(
                'filmIds',
                'Required parameter "filmIds" was null or undefined when calling personFilmRelationsExist().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        if (requestParameters['filmIds'] != null) {
            queryParameters['filmIds'] = requestParameters['filmIds']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/film-person-relations/film-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Check for existing relations between person and given film ids
     */
    async personFilmRelationsExist(requestParameters: PersonFilmRelationsExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.personFilmRelationsExistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for films with status confirmed.
     */
    async searchConfirmedFilmReferencesRaw(requestParameters: SearchConfirmedFilmReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['editionId'] != null) {
            queryParameters['editionId'] = requestParameters['editionId'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for films with status confirmed.
     */
    async searchConfirmedFilmReferences(requestParameters: SearchConfirmedFilmReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmReferencePageDto> {
        const response = await this.searchConfirmedFilmReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for film list items.
     */
    async searchFilmListItemsRaw(requestParameters: SearchFilmListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for film list items.
     */
    async searchFilmListItems(requestParameters: SearchFilmListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmPageDto> {
        const response = await this.searchFilmListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for person film relations by film.
     */
    async searchFilmPersonRelationByFilmRaw(requestParameters: SearchFilmPersonRelationByFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FilmPersonPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchFilmPersonRelationByFilm().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/film-person-relations/film/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilmPersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for person film relations by film.
     */
    async searchFilmPersonRelationByFilm(requestParameters: SearchFilmPersonRelationByFilmRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FilmPersonPageDto> {
        const response = await this.searchFilmPersonRelationByFilmRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for person film relations by person.
     */
    async searchFilmPersonRelationByPersonRaw(requestParameters: SearchFilmPersonRelationByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonFilmPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchFilmPersonRelationByPerson().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/films/film-person-relations/person/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonFilmPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for person film relations by person.
     */
    async searchFilmPersonRelationByPerson(requestParameters: SearchFilmPersonRelationByPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonFilmPageDto> {
        const response = await this.searchFilmPersonRelationByPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
