/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FilmPersonRelationTypeDto } from './FilmPersonRelationTypeDto';
import {
    FilmPersonRelationTypeDtoFromJSON,
    FilmPersonRelationTypeDtoFromJSONTyped,
    FilmPersonRelationTypeDtoToJSON,
} from './FilmPersonRelationTypeDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Person relations by film
 * @export
 * @interface FilmPersonListItemDto
 */
export interface FilmPersonListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FilmPersonListItemDto
     */
    id: string;
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof FilmPersonListItemDto
     */
    personId: string;
    /**
     * 
     * @type {FilmPersonRelationTypeDto}
     * @memberof FilmPersonListItemDto
     */
    relationType: FilmPersonRelationTypeDto;
    /**
     * 
     * @type {string}
     * @memberof FilmPersonListItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof FilmPersonListItemDto
     */
    surname: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof FilmPersonListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the FilmPersonListItemDto interface.
 */
export function instanceOfFilmPersonListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('personId' in value)) return false;
    if (!('relationType' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function FilmPersonListItemDtoFromJSON(json: any): FilmPersonListItemDto {
    return FilmPersonListItemDtoFromJSONTyped(json, false);
}

export function FilmPersonListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilmPersonListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'personId': json['personId'],
        'relationType': FilmPersonRelationTypeDtoFromJSON(json['relationType']),
        'firstName': json['firstName'],
        'surname': json['surname'],
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function FilmPersonListItemDtoToJSON(value?: FilmPersonListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'personId': value['personId'],
        'relationType': FilmPersonRelationTypeDtoToJSON(value['relationType']),
        'firstName': value['firstName'],
        'surname': value['surname'],
        'version': VersionDtoToJSON(value['version']),
    };
}

