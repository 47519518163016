import React from 'react';
import { mailingTemplateIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import FlexModuleSettingsControl from './mailing-template-form/modules/flex-module-settings-control';
import {
  MODULE_SETTINGS_CONTROL_EXTENSION,
  ModuleSettingsControlExtension,
} from './mailing-template-form/modules/module-settings-module-control';
import TextModuleSettingsControl from './mailing-template-form/modules/text-module-settings-control';
import { useMailingTemplatePermissionClassGroupExtension } from './mailing-template-permissions';
import MailingTemplateSearchResult from './mailing-template-search-result';

export default function MailingTemplatePluginProvider(registry: PluginRegistry) {
  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'TEXT',
    render({ name }) {
      return <TextModuleSettingsControl name={name} />;
    },
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'FLEX',
    render({ name, mailingType }) {
      return <FlexModuleSettingsControl name={name} mailingType={mailingType} />;
    },
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'RSVP',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'OFFER',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'SALUTATION',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'TICKET',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'TICKET_WITHOUT_PLACEMENT',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'PICTURE',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'PORTRAIT',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'ANCHOR',
  });

  registry.register<ModuleSettingsControlExtension>(MODULE_SETTINGS_CONTROL_EXTENSION, {
    type: 'CODE_CONFIG',
  });

  registry.register(SEARCH_RESULT_CONTENT, {
    type: 'MAILING_TEMPLATE',
    Component: MailingTemplateSearchResult,
  });
  registry.register(SEARCH_FILTER, {
    key: 'MAILING_TEMPLATE',
    icon: mailingTemplateIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.MAILING_TEMPLATE" />,
    necessaryPermission: 'MAILING_TEMPLATE:CAN_SEE_DETAILS',
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useMailingTemplatePermissionClassGroupExtension);
}
