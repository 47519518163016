/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface GroupTemplateReferenceDto
 */
export interface GroupTemplateReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GroupTemplateReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupTemplateReferenceDto
     */
    name: string;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof GroupTemplateReferenceDto
     */
    owners: Array<SectionReferenceDto>;
}

/**
 * Check if a given object implements the GroupTemplateReferenceDto interface.
 */
export function instanceOfGroupTemplateReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owners' in value)) return false;
    return true;
}

export function GroupTemplateReferenceDtoFromJSON(json: any): GroupTemplateReferenceDto {
    return GroupTemplateReferenceDtoFromJSONTyped(json, false);
}

export function GroupTemplateReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTemplateReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'owners': ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
    };
}

export function GroupTemplateReferenceDtoToJSON(value?: GroupTemplateReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'owners': ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
    };
}

